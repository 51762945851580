import { Group } from "@mantine/core"
import AuthoritiveLogoLink from "../../../../shared/components/AuthoritiveLogo"
import AppText from "../../../../shared/components/AppText"

export default function AiGeneratePageHeader() {
  return (
    <Group
      py={"sm"}
      px={12}
      sx={{
        height: 56
      }}
    >
      <Group
        position={"left"}
        sx={{ height: "100%", borderSpacing: "border-box" }}
        align={"center"}
        spacing={"xs"}
      >
        <AuthoritiveLogoLink />
        <AppText type={"title-medium"} color={"text/inverted"}>
          New AI Program
        </AppText>
      </Group>
    </Group>
  )
}
