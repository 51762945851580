import { Link } from "react-router-dom"
import { AuthoritiveLogoSingleLetter } from "../../logo"
import { Box } from "@mantine/core"

export default function AuthoritiveLogoLink({
  path = "/",
  ...props
}: {
  path?: string
}) {
  return (
    <Box
      component={Link}
      to={path}
      sx={{
        textDecoration: "none",
        display: "flex",
        alignItems: "center"
      }}
    >
      <AuthoritiveLogoSingleLetter
        dark
        sx={{
          transformOrigin: "center",
          transform: "scale(1)",
          transition: "transform ease-out 100ms",
          "&:active": {
            transform: "scale(1)"
          },
          "&:hover": {
            transform: "scale(1.1)"
          }
        }}
      />
    </Box>
  )
}
