import { PlatformGqlSchema } from "@authoritive/platform-service/src/platform.gqlzod"
import { z } from "zod"
import { AIServiceGqlZodSchema } from "@authoritive/ai-service/src/AIServiceSchema.gqlzod"
import { FullSignUpSchema } from "../formSchemas"

export type Program = Omit<
  z.infer<typeof PlatformGqlSchema.types.Program>,
  "product"
>
export type TopicFull = z.infer<typeof PlatformGqlSchema.types.Topic>
export type Topic = Omit<TopicFull, "flow">
export type DayOfWeek = z.infer<typeof PlatformGqlSchema.types.DayOfWeek>
export type ProgramStatus = typeof PlatformGqlSchema.types.ProgramStatus._type
export type AccountUser = typeof PlatformGqlSchema.types.AccountUser._type
export type StripeAccount = typeof PlatformGqlSchema.types.StripeAccount._type
export const UserRoles = PlatformGqlSchema.types.UserRole.Values
export const TicketStatus = AIServiceGqlZodSchema.types.TicketStatus.Values

export type Product = Omit<
  z.infer<typeof PlatformGqlSchema.types.Product>,
  "fulfillment" | "name" | "defaultListing"
>

export type Listing = Pick<
  z.infer<typeof PlatformGqlSchema.types.Listing>,
  | "slug"
  | "customization"
  | "name"
  | "published"
  | "analytics"
  | "emailMarketing"
>

export type AnalyticsType = z.infer<
  typeof PlatformGqlSchema.types.AnalyticsConfig
>
export type EmailMarketingType = z.infer<
  typeof PlatformGqlSchema.types.EmailMarketingConfig
>

export type AccountPlanTemplate = Omit<
  z.infer<typeof PlatformGqlSchema.types.AccountPlanTemplate>,
  "features" | "defaultProgramPlans"
>

export type AccountPlan = Omit<
  z.infer<typeof PlatformGqlSchema.types.AccountPlan>,
  | "accountId"
  | "pricing"
  | "defaultProgramPlans"
  | "shortDescription"
  | "featureDescription"
  | "features"
>

export type Discount = z.infer<typeof PlatformGqlSchema.types.Discount>

export const DaysOfWeek = PlatformGqlSchema.types.DayOfWeek._def.values

export const ProgramStatuses = PlatformGqlSchema.types.ProgramStatus.Values

export const WeekDayLabels = {
  MON: "Monday",
  TUE: "Tuesday",
  WED: "Wednesday",
  THU: "Thursday",
  FRI: "Friday",
  SAT: "Saturday",
  SUN: "Sunday"
} as const

export const MASKS = {
  TOPIC_MASK_WITHOUT_FLOW: {
    id: true,
    programId: true,
    name: true,
    cadenceOrder: true,
    firstMessageTime: true
  },
  TOPIC_MASK_WITH_FLOW: {
    id: true,
    programId: true,
    name: true,
    cadenceOrder: true,
    firstMessageTime: true,
    flow: { elements: true, version: true }
  },
  PROGRAM_MASK: {
    id: true,
    name: true,
    objective: true,
    cadence: true,
    durationDays: true,
    firstMessageTime: true,
    startDate: true,
    status: true,
    coverPhotoUrl: true,
    updatedAt: true,
    productId: true,
    submission: {
      id: true,
      accountId: true,
      programId: true,
      createdAt: true,
      status: true,
      notes: true,
      createdBy: true
    },
    product: {
      id: true,
      pricing: {
        basePrice: true,
        discount: { type: true, amount: true },
        bulkTiers: { low: true, discount: { type: true, amount: true } },
        currency: true
      },
      fulfillment: {
        $on: {
          ProgramFulfillment: {
            __typename: true,
            deploymentId: true
          },
          VoidFulfillment: {
            __typename: true
          },
          HttpFulfillment: {
            __typename: true
          }
        }
      },
      defaultListing: {
        name: true,
        slug: true,
        published: true,
        customization: {
          title: true,
          description: true,
          primaryColor: true,
          backgroundColor: true,
          heroImageUrl: true,
          navLinks: { label: true, url: true },
          font: true
        },
        analytics: {
          googleAds: { enabled: true, conversionTrackingTag: true },
          pixel: {
            enabled: true,
            domainVerificationCode: true,
            pixelId: true,
            conversionsApiAccessToken: true
          }
        },
        emailMarketing: {
          klaviyo: { enabled: true, privateApiKey: true },
          convertKit: { enabled: true, apiSecretKey: true },
          mailChimp: {
            enabled: true,
            dc: true,
            accessToken: true,
            listId: true,
            storeId: true
          }
        }
        // eslint-disable-next-line prettier/prettier
      } //satisfies GQLZodSelector<typeof PlatformGqlSchema.types.Listing>,
    }
  },
  PRODUCT_MASK: {
    id: true,
    pricing: {
      basePrice: true,
      discount: { type: true, amount: true },
      bulkTiers: { low: true, discount: { type: true, amount: true } }
    }
  },
  PROMOCODE_MASK: {
    id: true,
    productId: true,
    validUntil: true,
    limit: true,
    code: true,
    discount: { type: true, amount: true }
  },
  PROMOCODE_MASK_PAGINATED: {
    nextToken: true,
    items: {
      id: true,
      productId: true,
      validUntil: true,
      limit: true,
      code: true,
      discount: { type: true, amount: true }
    }
  },
  LISTING_MASK: {
    name: true,
    slug: true,
    customization: {
      title: true,
      description: true,
      primaryColor: true,
      backgroundColor: true,
      heroImageUrl: true,
      navLinks: { label: true, url: true },
      font: true
    }
  },
  ACCOUNT_PLAN_TEMPLATE_MASK: {
    id: true,
    name: true,
    featureDescription: true,
    shortDescription: true,
    level: true,
    pricing: { monthly: true, yearly: true }
  },
  ACTIVE_ACCOUNT_PLAN_MASK: {
    id: true,
    templateId: true,
    name: true,
    level: true,
    billing: true,
    createdAt: true,
    endDate: true,
    startDate: true,
    features: { maxPublished: true }
  },
  ACCOUNT_MASK: {
    id: true,
    name: true,
    stripeAccountId: true,
    milestones: true
  },
  STRIPE_CUSTOMER_MASK: {
    id: true,
    invoice_settings: { default_payment_method: true }
  },
  SALES_REPORT_MASK: {
    interv: true,
    bucket_start: true,
    bucket_end: true,
    program_id: true,
    program_name: true,
    orders: true,
    promo_code: true,
    amount: true,
    units: true,
    avg_initial_price: true,
    avg_final_price: true
  },
  PURCHASE_REPORT_MASK: {
    total: true,
    data: {
      id: true,
      email: true,
      programId: true,
      programName: true,
      type: true,
      quantity: true,
      promoCode: true,
      finalAmount: true,
      initialAmount: true,
      date: true
    }
  }
} as const

export type AlertType = "error" | "warning" | "info"

export const rolePermsDescription: Record<keyof typeof UserRoles, string> = {
  ADMIN: "Full access",
  EDITOR: "Edit/View Program content",
  VIEWER: "View only",
  REVIEWER: "View only",
  INACTIVE: "No access to Account"
}

export const planBillingData = [
  {
    label: "Monthly pricing",
    value: "monthly",
    description: "Plan paid monthly"
  },
  {
    label: "Annual pricing",
    value: "yearly",
    description: "Plan prepaid yearly"
  }
]

export type PromoDataType = {
  count: number
  amount: number
  promo: string
}

export type SalesPlotDataType = {
  programId: string
  programName: string
  imageUrl: string | undefined
  units: number
  amount: number
  basePrice: number
  discount: number | null
  avgPromo: number | null
  totalPromo: number | null
  avgSalesPrice: number | null
  promo?: PromoDataType[] | null
}

export type IntervType = z.infer<
  typeof PlatformGqlSchema.types.SalesReport.shape.interv
>

export type SalesPlotType = {
  interv: IntervType
  startDate: Date
  endDate: Date
  data: SalesPlotDataType[]
}

export type PurchaseReportRowType = z.infer<
  typeof PlatformGqlSchema.types.PurchaseReportRow
>

export type SignUpSchemaType = z.infer<typeof FullSignUpSchema>

export type WebsiteContentType = z.infer<
  typeof PlatformGqlSchema.types.ValidateSourceContentOutput
>

export type SalesReportIntervalType = z.infer<
  typeof PlatformGqlSchema.types.SalesReport.shape.interv
>

export type BulkTier = {
  low: number
  discount: Discount
}

export type PricingFormSchema = {
  basePrice: number
  discount?: Discount | null
  bulkTiers: BulkTier[]
  currency: string
}
