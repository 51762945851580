import {
  platformClient,
  PlatformClient,
  PlatformTypedGraphQlRequest
} from "./PlatformClient"
import { globalQueryClient, whoAmIRequest } from "../../App"

type HookObject = {
  [key in PlatformTypedGraphQlRequest["opName"]]?: (
    req: PlatformTypedGraphQlRequest & { opName: key },
    client: PlatformClient
  ) => void
}

const debounce = <Fn extends Function>(fn: Fn, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}

const emitContentUpdateDebounced = debounce(
  (args: { programId: string; accountId: string; email: string }) => {
    platformClient.emitEvent(
      {
        event: {
          type: "TOPIC_CONTENT_UPDATED",
          payload: {
            programId: args.programId,
            accountId: args.accountId,
            creatorEmail: args.email
          }
        }
      },
      true
    )
  },
  1000 * 15
)

const emitProgramInfoUpdateDebounced = debounce(
  (args: {
    programId: string
    accountId: string
    creatorEmail: string
    programName: string
  }) => {
    platformClient.emitEvent(
      {
        event: {
          type: "PROGRAM_INFO_UPDATED",
          payload: {
            programId: args.programId,
            accountId: args.accountId,
            creatorEmail: args.creatorEmail,
            programName: args.programName
          }
        }
      },
      true
    )
  },
  1000 * 15
)

/**
 * get programId from window path
 * This is also how requests get access to id so there shouldn't be any issue
 * It makes it easier to obtain programId for Listing & Product entities
 */
function getCurrentProgram(): { id: string; name: string } | undefined {
  const programId = window.location.pathname.match(
    /\/programs\/([0-9A-Z]+)/
  )?.[1]
  if (!programId) return
  const program = globalQueryClient
    .getQueryCache()
    .find<{ id: string; name: string }>(["program", programId])?.state?.data
  return program
}

export const postRequestHooks: HookObject = {
  createProgram: async (req, client) => {
    const { activeAccountId, email } = await globalQueryClient.fetchQuery(
      whoAmIRequest
    )
    client.emitEvent(
      {
        event: {
          type: "PROGRAM_CREATED",
          payload: {
            programId: req.result.id as string,
            accountId: activeAccountId,
            creatorEmail: email
          }
        }
      },
      true
    )
  },

  archiveProgram: async (req, client) => {
    const { activeAccountId, email } = await globalQueryClient.fetchQuery(
      whoAmIRequest
    )
    client.emitEvent(
      {
        event: {
          type: "PROGRAM_ARCHIVED",
          payload: {
            programId: req.args.id,
            accountId: activeAccountId,
            creatorEmail: email,
            programName: (req.result.name as string) ?? ""
          }
        }
      },
      true
    )
  },

  unarchiveProgram: async (req, client) => {
    const { activeAccountId, email } = await globalQueryClient.fetchQuery(
      whoAmIRequest
    )
    client.emitEvent(
      {
        event: {
          type: "PROGRAM_UNARCHIVED",
          payload: {
            programId: req.args.id,
            accountId: activeAccountId,
            creatorEmail: email,
            programName: (req.result.name as string) ?? ""
          }
        }
      },
      true
    )
  },

  createTopic: async (req, client) => {
    const { activeAccountId, email } = await globalQueryClient.fetchQuery(
      whoAmIRequest
    )
    client.emitEvent(
      {
        event: {
          type: "TOPIC_CREATED",
          payload: {
            programId: req.args.programId,
            accountId: activeAccountId,
            creatorEmail: email
          }
        }
      },
      true
    )
  },

  updateFlow: async () => {
    const { activeAccountId, email } = await globalQueryClient.fetchQuery(
      whoAmIRequest
    )

    const program = getCurrentProgram()

    if (!program) {
      alert("no program")
      return
    }
    emitContentUpdateDebounced({
      programId: program.id,
      accountId: activeAccountId,
      email
    })
  },

  updateProgram: async (req) => {
    const { activeAccountId, email } = await globalQueryClient.fetchQuery(
      whoAmIRequest
    )

    emitProgramInfoUpdateDebounced({
      programId: req.args.id,
      accountId: activeAccountId,
      creatorEmail: email,
      programName: req.args.name ?? ""
    })
  },

  updateProduct: async (req, client) => {
    const { activeAccountId, email } = await globalQueryClient.fetchQuery(
      whoAmIRequest
    )

    const program = getCurrentProgram()

    if (!program) return

    client.emitEvent(
      {
        event: {
          type: "PRICING_UPDATED",
          payload: {
            programId: program.id,
            accountId: activeAccountId,
            creatorEmail: email
          }
        }
      },
      true
    )
  },

  importProgram: async (req, client) => {
    const { activeAccountId, email } = await globalQueryClient.fetchQuery(
      whoAmIRequest
    )
    client.emitEvent(
      {
        event: {
          type: "AI_PROGRAM_CREATED",
          payload: {
            programId: req.result,
            accountId: activeAccountId,
            creatorEmail: email,
            title: req.args.program.title,
            duration: req.args.program.topics.length
          }
        }
      },
      true
    )
  },

  createListing: async (req, client) => {
    const { activeAccountId, email } = await globalQueryClient.fetchQuery(
      whoAmIRequest
    )

    const program = getCurrentProgram()

    if (!program) return

    client.emitEvent(
      {
        event: {
          type: "PUBLISH_PAGE_CREATED",
          payload: {
            programId: program.id,
            accountId: activeAccountId,
            creatorEmail: email,
            slug: req.args.slug
          }
        }
      },
      true
    )
  },

  updateListing: async (req, client) => {
    const { activeAccountId, email } = await globalQueryClient.fetchQuery(
      whoAmIRequest
    )
    const program = getCurrentProgram()

    if (!program) return

    if (req.args.published !== undefined) {
      if (req.args.published) {
        client.emitEvent(
          {
            event: {
              type: "PROGRAM_PUBLISHED",
              payload: {
                programId: program.id,
                accountId: activeAccountId,
                programName: program.name,
                creatorEmail: email,
                slug: req.args.slug
              }
            }
          },
          true
        )
      } else {
        client.emitEvent(
          {
            event: {
              type: "PROGRAM_UNPUBLISHED",
              payload: {
                programId: program.id,
                accountId: activeAccountId,
                programName: program.name,
                creatorEmail: email,
                slug: req.args.slug
              }
            }
          },
          true
        )
      }

      return
    }

    client.emitEvent(
      {
        event: {
          type: "PUBLISH_PAGE_UPDATED",
          payload: {
            programId: program.id,
            accountId: activeAccountId,
            creatorEmail: email,
            slug: req.args.slug
          }
        }
      },
      true
    )
  },

  createStripeAccount: async (req, client) => {
    const { activeAccountId, email } = await globalQueryClient.fetchQuery(
      whoAmIRequest
    )

    client.emitEvent(
      {
        event: {
          type: "STRIPE_CONNECT_INITIATED",
          payload: {
            accountId: activeAccountId,
            creatorEmail: email
          }
        }
      },
      true
    )
  },

  submitForReview: async (req, client) => {
    const { activeAccountId, email } = await globalQueryClient.fetchQuery(
      whoAmIRequest
    )

    const program = getCurrentProgram()

    if (!program) return

    client.emitEvent(
      {
        event: {
          type: "PROGRAM_SUBMITTED_FOR_REVIEW",
          payload: {
            programId: program.id,
            programName: program.name,
            accountId: activeAccountId,
            creatorEmail: email,
            submissionId: req.result.id as string
          }
        }
      },
      true
    )
  }
}
