import {
  Card,
  Flex,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Radio,
  Select,
  SimpleGrid,
  Stack,
  TextInput
} from "@mantine/core"
import AppText from "../../../../../../shared/components/AppText"
import CardTitle from "../../../../../../shared/components/CardTitle"
import AppButton from "../../../../../../shared/components/AppButton"
import { ReactNode } from "react"
import { useSignUpFormContext } from "../../index"
import { businessSubjects } from "./subjects"

function InputCard(props: {
  title: string
  children: ReactNode
  description?: string
}) {
  return (
    <Card sx={{ overflow: "unset" }} bg={"bg/primary-l2"}>
      <Stack>
        <Stack spacing={0}>
          <CardTitle title={props.title} type={"body-large"} />
          {props.description && (
            <AppText type={"body-small"} color={"text/dimmed"}>
              {props.description}
            </AppText>
          )}
        </Stack>
        <Paper>{props.children}</Paper>
      </Stack>
    </Card>
  )
}

export default function CompleteSurveyComponent(props: {
  visible: boolean
  isLoading?: boolean
  onClickBack: () => void
}) {
  const form = useSignUpFormContext()

  return (
    <Stack
      justify="space-between"
      display={props.visible ? "flex" : "none"}
      sx={{ height: "100%" }}
    >
      <LoadingOverlay visible={props.isLoading ?? false} />

      <Stack spacing={4}>
        <AppText type={"title-large"} color={"text/default"}>
          Tell us a little more about yourself
        </AppText>
        <AppText type={"body-small"} color={"text/dimmed"}>
          Your answers will help us build an experience to match your needs
        </AppText>
      </Stack>
      <Stack spacing={8}>
        <InputCard title={"Do you currently share content with your audience?"}>
          <Stack spacing={8}>
            <Radio.Group
              spacing={8}
              sx={{
                ".mantine-Group-root": {
                  paddingTop: 0
                }
              }}
              {...form.getInputProps("onlineContent")}
              value={
                form.values.onlineContent === null
                  ? undefined
                  : form.values.onlineContent === false
                  ? "N"
                  : "Y"
              }
              onChange={(v) =>
                form
                  .getInputProps("onlineContent")
                  .onChange(v === "Y" ? "" : false)
              }
            >
              <Radio label={"Yes"} value={"Y"} />
              <Radio label={"No"} value={"N"} />
            </Radio.Group>
            {typeof form.values.onlineContent === "string" && (
              <TextInput
                name={"contentUrl"}
                autoComplete={"off"}
                placeholder={
                  "Website, Blog, Substack, Youtube URL, or Social handle"
                }
                {...form.getInputProps("onlineContent")}
                error={null}
              />
            )}
          </Stack>
        </InputCard>

        <InputCard
          title={"What's your best estimate of your current audience size?"}
          description={
            "(Email list, social media followers, subscribers, podcast downloads, etc.)"
          }
        >
          <Radio.Group
            name={"audienceSize"}
            sx={{
              ".mantine-Group-root": {
                paddingTop: 0
              }
            }}
            {...form.getInputProps("audienceSize")}
          >
            <SimpleGrid
              spacing={8}
              breakpoints={[{ maxWidth: 668, cols: 1 }]}
              cols={2}
            >
              <Radio label={"1 - 10.000"} value={"1-10000"} />
              <Radio label={"10.000 - 50.000"} value={"10000-50000"} />
              <Radio label={"50.000 - 500.000"} value={"50000-500000"} />
              <Radio label={"500.000+"} value={"500000+"} />
            </SimpleGrid>
          </Radio.Group>
        </InputCard>

        <InputCard
          title={
            "What genre of content do you create and share? Pick the ones that feels closest."
          }
        >
          <Select
            name={"contentGenre"}
            dropdownPosition={"top"}
            placeholder={"Select genre"}
            data={[
              ...businessSubjects
                .map((s) => ({
                  label: s,
                  value: s
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
              { label: "Something else", value: "else" }
            ]}
            {...form.getInputProps("genre")}
          />
        </InputCard>
        <Flex gap={"xs"}>
          <AppButton
            sx={{
              flex: 1
            }}
            loading={props.isLoading}
            onClick={props.onClickBack}
            size={"md"}
            label={"Back"}
            textStyle={"button-large"}
            textColor={"text/default"}
            color={"button/secondary"}
          />
          <AppButton
            sx={{
              flex: 3
            }}
            loading={props.isLoading}
            type={"submit"}
            size={"md"}
            label={"Complete Registration"}
            textStyle={"button-large"}
            textColor={"text/default"}
            color={"button/primary"}
          />
        </Flex>
      </Stack>
    </Stack>
  )
}
