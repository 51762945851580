import {
  generateGraphQLRequest,
  GraphQLRequest,
  RequestFormatOptions,
  stitchRequests
} from "./generateGraphQLRequest"
import fetch from "cross-fetch"
import { mapObject } from "./generateGraphQLSchema"

const randomId = () => "reqId__" + Math.random().toString(36).substring(2)

interface GraphQLClientOptions {
  endpoint: string
  headers?: () => Promise<Record<string, string>>
  stitchDebounce?: number
  requestFormat?: RequestFormatOptions
}

function debounce<F extends (...params: any[]) => void>(fn: F, delay: number) {
  let timeoutID: ReturnType<typeof setTimeout> | null = null
  return function (this: any, ...args: any[]) {
    if (timeoutID) clearTimeout(timeoutID)
    timeoutID = setTimeout(() => fn.apply(this, args), delay)
  } as F
}

function tryParseAsObject(str: string): Record<string, unknown> | undefined {
  try {
    const res = JSON.parse(str)
    if (typeof res === "object") return res
    if (typeof res === "string") return tryParseAsObject(res)
    return undefined
  } catch (e) {
    return undefined
  }
}

export class SimpleGraphQLClient {
  constructor(private options: GraphQLClientOptions) {}

  private formatResponse = (res: unknown): unknown => {
    const DATE_REGEX = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+Z?$/
    if (!res) return res
    if (typeof res === "string" && res.match(DATE_REGEX)) {
      return new Date(res)
    }
    if (typeof res === "string") {
      const jsObj = tryParseAsObject(res)
      if (jsObj) return this.formatResponse(jsObj)
    }
    if (Array.isArray(res)) return res.map((it) => this.formatResponse(it))
    if (typeof res === "object") {
      return mapObject(res as Record<string, unknown>, this.formatResponse)
    }
    return res
  }

  private groupReq: {
    [tp in GraphQLRequest["opType"]]: {
      [reqKey: string]: {
        req: GraphQLRequest
        res: (d: any) => void
        rej: (str: any) => void
      }
    }
  } = { query: {}, mutation: {} }

  private debounceOperation = (op: GraphQLRequest["opType"]) => async () => {
    const groupReq = this.groupReq[op]
    this.groupReq[op] = {}
    const stichedRequest = stitchRequests(
      mapObject(groupReq, (r) => r.req),
      this.options.requestFormat
    )

    console.log("Making debounced request", op, stichedRequest, groupReq)
    const res = await this.makeRequestSafeStr(stichedRequest)

    if ("errors" in res) {
      Object.values(groupReq).forEach((rd) => {
        try {
          rd.rej((res.errors as string[])[0])
          // eslint-disable-next-line no-empty
        } catch (e) {}
      })
      return
    }

    if (
      !Object.keys(groupReq).every((e) => Object.keys(res).includes(e)) // not matching response
    )
      return
    else {
      Object.entries(groupReq).forEach(([k, rd]) => {
        rd.res(res[k])
      })
    }
  }

  private debouncedQuery = debounce(
    this.debounceOperation("query"),
    this.options.stitchDebounce || 0
  )

  private debouncedMutation = debounce(
    this.debounceOperation("mutation"),
    this.options.stitchDebounce || 0
  )

  // TODO add debounce to stitch consequent requests
  public async makeRequest<T = any>(req: GraphQLRequest): Promise<T> {
    if (!this.options.stitchDebounce) {
      const res = await this.makeRequestSafe(req)
      if ("errors" in res) {
        console.log("error:", res.errors)
        throw new Error(res.errors[0])
      }
      return res.data
    } else {
      const reqKey = randomId()
      const retPromise = new Promise<T>((res, rej) => {
        this.groupReq[req.opType][reqKey] = { req, res, rej }
        if (req.opType === "query") this.debouncedQuery()
        if (req.opType === "mutation") this.debouncedMutation()
      })
      return retPromise
    }
  }

  private makeRequestSafeStr = async <T = any>(
    query: string
  ): Promise<{ [p: string]: unknown } | { errors: string[] }> => {
    const headers = await this.options.headers?.()
    try {
      const resp = await fetch(this.options.endpoint, {
        method: "POST",
        headers: { ...headers, "content-type": "application/json" },
        body: JSON.stringify({ query })
      }).then((r) => r.json())
      if ("errors" in resp) return { errors: resp.errors }
      return mapObject(resp["data"], this.formatResponse)
    } catch (e: any) {
      return {
        errors: [
          "message" in e && typeof e.message === "string"
            ? e.message
            : "Unknown error"
        ]
      }
    }
  }

  private makeRequestSafe = async <T = any>(
    req: GraphQLRequest,
    alias?: string
  ): Promise<{ data: T } | { errors: string[] }> => {
    const reqKey = alias ?? "default"
    const headers = await this.options.headers?.()
    const reqBody = generateGraphQLRequest(req, {
      alias: reqKey,
      ...this.options.requestFormat
    })
    try {
      const resp = await fetch(this.options.endpoint, {
        method: "POST",
        headers: { ...headers, "content-type": "application/json" },
        body: JSON.stringify({ query: reqBody })
      }).then((r) => r.json())

      if (resp["errors"])
        return { errors: resp.errors.map((err: any) => err.message as string) }
      if (resp["data"]) resp["data"] = this.formatResponse(resp["data"][reqKey])
      return resp
    } catch (e: any) {
      return {
        errors: [
          "message" in e && typeof e.message === "string"
            ? e.message
            : "Unknown error"
        ]
      }
    }
  }
}
