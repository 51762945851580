import { ButtonHTMLAttributes, forwardRef } from "react"
import { Button, ButtonProps, packSx } from "@mantine/core"
import { platformColors } from "../../theme/colors"
import { platformTypography } from "../../theme/typography"

interface AppButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string
  textStyle: keyof typeof platformTypography
  textColor: keyof typeof platformColors
}

const AppButton = forwardRef<HTMLButtonElement, AppButtonProps & ButtonProps>(
  (props, ref) => {
    const { textStyle, textColor, label, ...restProps } = props
    return (
      <Button
        ref={ref}
        size={"xs"}
        px={"xs"}
        color={props.color}
        {...restProps}
        sx={[
          (th) => ({
            ...th.other.text[textStyle],
            color: th.colors[textColor][0]
          }),
          ...packSx(props.sx)
        ]}
      >
        {label}
      </Button>
    )
  }
)

export default AppButton
