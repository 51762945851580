import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAuthContext } from "../AuthContext"
import { Auth } from "aws-amplify"
import { useForm, zodResolver } from "@mantine/form"
import { IconMail, IconKey } from "@tabler/icons"

import {
  Anchor,
  Button,
  Group,
  LoadingOverlay,
  PasswordInput,
  Stack,
  TextInput,
  Title,
  Paper
} from "@mantine/core"
import { z } from "zod"
import Alert from "../../../../shared/components/Alert"
import AppText from "../../../../shared/components/AppText"
import ReactGA from "react-ga4"
import { globalQueryClient, whoAmIRequest } from "../../../../App"
import { platformClient } from "../../../../shared/services/PlatformClient"

const loginSchema = z.object({
  username: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Please insert an email address" }),
  password: z.string().min(1, { message: "Please enter a password" })
})

export default function LoginComponent() {
  const queryClient = useQueryClient()
  const { goToState } = useAuthContext()

  const {
    mutate: signIn,
    isLoading,
    error,
    isError
  } = useMutation(
    (args: { username: string; password: string }) =>
      Auth.signIn(args.username, args.password),
    {
      onError: (error: Error, variables) => {
        if (error.name === "UserNotConfirmedException")
          goToState("VerifyAccount", { username: variables.username })
      },
      onSuccess: () => {
        ReactGA.event({
          category: "user",
          action: "login"
        })
        globalQueryClient.fetchQuery(whoAmIRequest).then((r) => {
          platformClient.emitEvent(
            {
              event: {
                type: "CREATOR_LOGGED_IN",
                payload: {
                  accountId: r.activeAccountId,
                  creatorEmail: r.email,
                  creatorName: r.name
                }
              }
            },
            true
          )
        })
        queryClient.invalidateQueries(["session"])
      }
    }
  )

  const loginForm = useForm({
    initialValues: { username: "", password: "" },
    validate: zodResolver(loginSchema)
  })

  const handleLogin = () => {
    const valRes = loginForm.validate()
    if (valRes.hasErrors) return
    else
      signIn({
        username: loginForm.values.username,
        password: loginForm.values.password
      })
  }

  return (
    <Stack justify="space-between" sx={{ height: "100%" }}>
      <LoadingOverlay visible={isLoading} />
      <Group position="right" noWrap>
        <AppText type={"label-dimmed"} color={"text/half-dimmed"}>
          New on Authoritive?
        </AppText>
        <Button
          size="md"
          color="button/secondary"
          onClick={() => goToState("SignUp", undefined)}
        >
          <AppText type={"button-large"} color={"text/default"}>
            Create Account
          </AppText>
        </Button>
      </Group>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleLogin()
        }}
      >
        <Stack>
          <Title order={1} mb="xl">
            Log-in
          </Title>
          <Paper p="xl">
            <TextInput
              autoFocus
              icon={<IconMail size={20} />}
              pb="xs"
              placeholder={"Email"}
              {...loginForm.getInputProps("username")}
            />
            <PasswordInput
              icon={<IconKey size={20} />}
              pb="xs"
              placeholder={"Password"}
              {...loginForm.getInputProps("password")}
            />
            <Anchor
              onClick={() => goToState("ForgotPassword", undefined)}
              sx={(th) => th.other.text["button-large"]}
            >
              Forgot password?
            </Anchor>
            {isError && <Alert type={"error"} message={error.message} />}
          </Paper>
          <Button size="md" type={"submit"} color="button/primary">
            <AppText type={"button-large"} color={"text/default"}>
              Continue
            </AppText>
          </Button>
        </Stack>
      </form>
    </Stack>
  )
}
