import {
  GQLZodNamedType,
  GQLZodOutputType,
  GQLZodPrimitiveType,
  GQLZodSchema,
  isPrimitive
} from "./GQLZodSchema"
import { z } from "zod"
import {
  GraphQLInputType,
  GraphQLOutputType,
  GraphQLScalarType,
  GraphQLList,
  GraphQLNonNull,
  GraphQLSchema,
  GraphQLUnionType,
  GraphQLNamedType,
  printSchema,
  GraphQLString,
  GraphQLObjectType,
  GraphQLEnumType,
  GraphQLInt,
  GraphQLArgumentConfig,
  GraphQLFloat,
  GraphQLNullableType,
  GraphQLNamedOutputType,
  GraphQLNamedInputType,
  GraphQLFieldConfig,
  GraphQLDirective,
  GraphQLDirectiveConfig,
  GraphQLBoolean,
  GraphQLDirectiveExtensions
} from "graphql"
import { match } from "ts-pattern"

// TODO extract somewhere as helper
export function mapObject<V, B>(obj: Record<string, V>, fn: (v: V) => B) {
  return Object.fromEntries(Object.entries(obj).map((e) => [e[0], fn(e[1])]))
}
export function filterObject<V, B>(
  obj: Record<string, V>,
  fn: (v: V) => boolean
) {
  return Object.fromEntries(Object.entries(obj).filter((e) => fn(e[1])))
}

export function generateGraphQLSchema(schema: GQLZodSchema) {
  const AWSDateTime = new GraphQLScalarType({ name: "AWSDateTime" })
  const AWSJson = new GraphQLScalarType({ name: "AWSJSON" })
  let typeMap: { name: string; zt: z.ZodType; gql: GraphQLNamedOutputType }[] =
    []

  const inputMap: {
    name: string
    zt: z.ZodType
    gql: GraphQLNamedInputType
  }[] = []

  function zodOptionalInner(zt: z.ZodType): z.ZodType {
    if (zt instanceof z.ZodNullable || zt instanceof z.ZodOptional)
      return zodOptionalInner(zt._def.innerType)
    return zt
  }

  function handleOptional<T extends z.ZodType, B extends GraphQLNullableType>(
    handler: (inner: T) => B
  ) {
    return (zt: T) => {
      if (zt instanceof z.ZodOptional || zt instanceof z.ZodNullable)
        return handler(zodOptionalInner(zt) as T)
      return new GraphQLNonNull(handler(zt))
    }
  }

  function primitiveToGql(zt: GQLZodPrimitiveType): GraphQLScalarType {
    if (zt instanceof z.ZodString) return GraphQLString
    if (zt instanceof z.ZodLiteral) return GraphQLString
    if (zt instanceof z.ZodNumber) return GraphQLInt
    if (zt instanceof z.ZodBoolean) return GraphQLBoolean
    if (zt instanceof z.ZodDate) return AWSDateTime
    if (zt instanceof z.ZodEnum) return GraphQLString
    if (zt instanceof z.ZodRecord) return AWSJson
    throw new Error("Unhandled primitive type:" + JSON.stringify(zt))
  }

  function findNamedType(zt: z.ZodType): GraphQLNamedOutputType {
    const typeEntry = typeMap.find((e) => e.zt === zt)
    if (!typeEntry) return AWSJson
    //throw new Error("Missing type entry for:" + JSON.stringify(zt))
    return typeEntry.gql
  }

  function outputToGql(zt: GQLZodOutputType): GraphQLOutputType {
    if (isPrimitive(zt)) return primitiveToGql(zt)
    if (zt instanceof z.ZodArray)
      return new GraphQLList(handleOptional(outputToGql)(zt.element))
    if (zt instanceof z.ZodObject || zt instanceof z.ZodDiscriminatedUnion) {
      return findNamedType(zt)
    }
    throw new Error("Unhandled output type:" + JSON.stringify(zt))
  }

  function namedToGql(
    name: string,
    zt: GQLZodNamedType
  ): GraphQLNamedOutputType {
    if (zt instanceof z.ZodObject) {
      return new GraphQLObjectType({
        name,
        fields: () =>
          mapObject(zt.shape, (t) => ({ type: handleOptional(outputToGql)(t) }))
      })
    }
    return new GraphQLUnionType({
      name,
      types: () =>
        ([...zt.options.values()] as z.ZodObject<z.ZodRawShape>[]).map(
          findNamedType
        ) as GraphQLObjectType[]
    })
  }

  function inputToGql(zt: z.ZodType): GraphQLInputType {
    if (isPrimitive(zt)) return primitiveToGql(zt)
    // TODO try to generate input types for further validation
    if (
      zt instanceof z.ZodDiscriminatedUnion ||
      zt instanceof z.ZodObject ||
      zt instanceof z.ZodArray ||
      zt instanceof z.ZodRecord
    )
      return AWSJson
    throw new Error("Unhandled input type:" + JSON.stringify(zt))
  }

  function argToArgument(zt: z.ZodType): GraphQLArgumentConfig {
    return {
      type: handleOptional(inputToGql)(zt)
    }
  }

  function isValidArgument(
    arg: unknown
  ): arg is z.ZodTuple<[z.ZodObject<z.ZodRawShape>]> {
    return (
      arg !== undefined &&
      arg instanceof z.ZodTuple &&
      arg.items.length === 1 &&
      arg.items[0] instanceof z.ZodObject
    )
  }

  function fnToFieldConfig(
    zt: z.ZodFunction<any, any>
  ): GraphQLFieldConfig<any, any> {
    const args = match(zt.parameters())
      .when(isValidArgument, (v) => {
        return mapObject(v.items[0].shape, argToArgument)
      })
      .otherwise(() => undefined)
    return {
      args: args,
      type: handleOptional(outputToGql)(zt.returnType())
    }
  }

  typeMap = Object.entries(schema.types)
    .filter((e) => !(e[1] instanceof z.ZodEnum))
    .map(([name, zt]) => ({
      name: name,
      zt: zt,
      gql: namedToGql(name, zt)
    }))

  const gqlSchema = new GraphQLSchema({
    types: typeMap.map((e) => e.gql),
    query: new GraphQLObjectType({
      name: "Query",
      fields: () => mapObject(schema.Query, fnToFieldConfig)
    }),
    mutation: new GraphQLObjectType({
      name: "Mutation",
      fields: () => mapObject(schema.Mutation, fnToFieldConfig)
    })
  })

  function applyAnnotations(schema: GQLZodSchema, gqlSchemaStr: string) {
    return Object.entries(schema.annotations ?? {}).reduce(
      (s, [tpe, annots]) => {
        return s.replace(
          new RegExp(`(type ${tpe}) *([{=])`, "g"),
          `$1 ${annots?.join(" ")} $2`
        )
      },
      gqlSchemaStr
    )
  }
  return applyAnnotations(schema, printSchema(gqlSchema))
}
