import { ReactNode } from "react"
import { Box, packSx, Sx } from "@mantine/core"

export default function WorkspaceContainer(props: {
  children: ReactNode
  sx?: Sx | Sx[]
}) {
  return (
    <Box
      sx={[
        (th) => ({
          flex: 1,
          overflowY: "hidden",
          borderRadius: th.radius.sm,
          margin: th.spacing.sm,
          marginTop: 0,
          backgroundColor: th.colors["bg/inverted-l2-ai"][0],
          padding: th.spacing.md,
          [th.fn.smallerThan("sm")]: {
            overflowY: "scroll"
          }
        }),
        ...packSx(props.sx)
      ]}
    >
      {props.children}
    </Box>
  )
}
