import { useStateContext } from "../AuthContext"
import { useMutation } from "@tanstack/react-query"
import { Auth } from "aws-amplify"
import { useForm, zodResolver } from "@mantine/form"
import {
  Button,
  LoadingOverlay,
  Stack,
  TextInput,
  Title,
  Paper
} from "@mantine/core"
import { z } from "zod"
import { IconMail } from "@tabler/icons"
import Alert from "../../../../shared/components/Alert"
import AppText from "../../../../shared/components/AppText"

const forgotPasswordSchema = z.object({
  username: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Please insert an email address" })
})

export default function ForgotPasswordComponent() {
  const { goToState } = useStateContext("ForgotPassword")

  const {
    mutate: forgotPassword,
    isLoading,
    error,
    isError
  } = useMutation(
    (args: { username: string }) => Auth.forgotPassword(args.username),
    {
      onSuccess: () =>
        goToState("ResetPassword", {
          username: forgotPasswordForm.values.username
        })
    }
  )

  const forgotPasswordForm = useForm({
    initialValues: { username: "" },
    validate: zodResolver(forgotPasswordSchema)
  })

  const handleForgotPassword = () => {
    const valRes = forgotPasswordForm.validate()
    if (valRes.hasErrors) return
    else
      forgotPassword({
        username: forgotPasswordForm.values.username
      })
  }

  return (
    <Stack justify="space-between" sx={{ height: "100%" }}>
      <LoadingOverlay visible={isLoading} />
      <Button
        size="md"
        ml={"auto"}
        color="button/secondary"
        onClick={() => goToState("Login", undefined)}
      >
        <AppText type={"button-large"} color={"text/default"}>
          Back to login
        </AppText>
      </Button>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleForgotPassword()
        }}
      >
        <Stack spacing="sm">
          <Title order={1} mb="xl">
            Forgot password
          </Title>
          <Paper p="xl">
            <TextInput
              autoFocus
              icon={<IconMail size={20} />}
              placeholder="Your email"
              type="email"
              {...forgotPasswordForm.getInputProps("username")}
            />
            {isError && error instanceof Error && (
              <Alert type={"error"} message={error.message} />
            )}
          </Paper>
          <Button type={"submit"} size="md" color="button/primary">
            <AppText type={"button-large"} color={"text/default"}>
              Reset my password
            </AppText>
          </Button>
        </Stack>
      </form>
    </Stack>
  )
}
