import { PlatformGqlSchema } from "@authoritive/platform-service/src/platform.gqlzod"
import {
  makeClientClass,
  TypedGraphQlRequest
} from "@authoritive/gql-zod/dist/GQLZodClient"
import { env } from "../../env/env"
import { Auth } from "aws-amplify"
import { MASKS } from "../types"
import { GraphQLRequest } from "@authoritive/gql-zod/dist/generateGraphQLRequest"
import { globalQueryClient, whoAmIRequest } from "../../App"
import { postRequestHooks } from "./postRequestHooks"

export type PlatformTypedGraphQlRequest = TypedGraphQlRequest<
  typeof PlatformGqlSchema
>

export class PlatformClient extends makeClientClass(PlatformGqlSchema) {
  findProgramAll(args: { id: string }) {
    return this.findProgram({ id: args.id }, MASKS.PROGRAM_MASK)
  }
  listTopicsWithoutFlow(args: { programId: string }) {
    return this.listTopics(args, MASKS.TOPIC_MASK_WITHOUT_FLOW)
  }
  findTopicWithFlow(args: { id: string }) {
    return this.findTopic(args, MASKS.TOPIC_MASK_WITH_FLOW)
  }

  async makeRequest<T = any>(req: GraphQLRequest): Promise<T> {
    const res = await super.makeRequest(req)
    const typedReq = { ...req, result: res } as TypedGraphQlRequest<
      typeof PlatformGqlSchema
    >
    try {
      //@ts-ignore
      postRequestHooks[typedReq.opName]?.(typedReq, this)
    } catch (e) {
      console.warn("Post request hook failed", e)
    }
    return res
  }
}
export const platformClient = new PlatformClient({
  stitchDebounce: 1,
  endpoint: env.REACT_APP_API_URL,
  headers: async () => ({
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
  }),
  requestFormat: {
    stringifyNestedObjects: true
  }
})
