import { z } from "zod"

export const passwordRequirements = [
  { re: /[0-9]/, label: "Password must contain at least one number" },
  { re: /[a-z]/, label: "Password must contain at least one lowercase letter" },
  {
    re: /[A-Z]/,
    label: "Password must contain at least one uppercase letter"
  },
  {
    re: /[$&+,:;=?@#|'<>.^*()%!-]/,
    label: "Password must contain at least one special symbol"
  }
]

export const resetPasswordSchema = z
  .object({
    code: z
      .string()
      .min(6, { message: "Verification code is not properly formatted" }),
    password: z.string().superRefine((val, ctx) => {
      if (val.length < 8) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Your password must be at least 8 characters long",
          fatal: true
        })

        return z.NEVER
      }

      passwordRequirements.map((req) => {
        if (!req.re.test(val)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: req.label
          })
        }
      })
    }),
    confirmPassword: z
      .string()
      .min(8, { message: "Your password must be at least 8 characters long" })
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"]
  })

export const SignUpSchema = z.object({
  userFullName: z
    .string()
    .min(2, { message: "Your Full Name must be at least 2 characters long" }),
  username: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Please insert an email address" }),
  password: z.string().superRefine((val, ctx) => {
    if (val.length < 8) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Your password must be at least 8 characters long",
        fatal: true
      })

      return z.NEVER
    }

    passwordRequirements.map((req) => {
      if (!req.re.test(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: req.label
        })
      }
    })
  }),
  confirmPassword: z
    .string()
    .min(8, { message: "Your password must be at least 8 characters long" }),
  terms: z
    .boolean()
    .refine((v) => v, { message: "Accept the terms to continue" }),
  accountId: z.string().optional(),
  referrer: z.string().optional()
})

export const SurveyFormSchema = z.object({
  onlineContent: z.union([
    z.literal(false),
    z.string().min(2, "Please fill in this field")
  ]),
  audienceSize: z.string().min(1, "Please select one of the options"),
  genre: z.string().min(1, "Please select one of the options")
})

export const FullSignUpSchema = SurveyFormSchema.extend(SignUpSchema.shape)

export const SignUpSchemaPasswordMatch = SignUpSchema.refine(
  (data) => data.password === data.confirmPassword,
  {
    message: "Passwords do not match",
    path: ["confirmPassword"]
  }
)

export const InvitationSchema = z.object({
  accountId: z.string(),
  name: z.string(),
  email: z.string().email().optional()
})

export const GenerateProgramFormSchema = z.object({
  duration: z.number().min(1).max(30),
  sourceType: z.enum(["youtube", "website", "objective"]),
  source: z.string().min(5)
})
