import {
  Anchor,
  Paper,
  Box,
  Checkbox,
  Group,
  LoadingOverlay,
  PasswordInput,
  Stack,
  TextInput,
  Title
} from "@mantine/core"
import AppText from "../../../../../../shared/components/AppText"
import AppButton from "../../../../../../shared/components/AppButton"
import { IconKey, IconMail, IconUser, IconUsers } from "@tabler/icons"
import { useAuthContext } from "../../../AuthContext"
import { useSignUpFormContext } from "../../index"
import AlertComponent from "../../../../../../shared/components/Alert"

export default function SignUpDetails(props: {
  visible: boolean
  isLoading?: boolean
  invitationName: string | undefined
  handleOnClick: () => void
  hasError: boolean | undefined
  error: unknown | undefined
  referrer: string | undefined
}) {
  const { goToState } = useAuthContext()

  const signUpForm = useSignUpFormContext()

  return (
    <Stack
      justify="space-between"
      sx={{ height: "100%" }}
      display={props.visible ? "flex" : "none"}
    >
      <LoadingOverlay visible={props.isLoading ?? false} />

      <Group position="right">
        <AppText type={"label-dimmed"} color={"text/half-dimmed"}>
          Do you have an account?
        </AppText>
        <AppButton
          onClick={() => goToState("Login", undefined)}
          size={"md"}
          label={"Login"}
          textStyle={"button-large"}
          textColor={"text/default"}
          color={"button/secondary"}
        />
      </Group>

      <Stack>
        <Title order={1} mb="xl">
          Sign up
        </Title>
        {props.invitationName && (
          <AppText type={"title-large"}>
            at{" "}
            <Box component={"a"} sx={{ fontWeight: "bold" }}>
              {props.invitationName}
            </Box>
            's Workspace
          </AppText>
        )}
        <Paper p="xl">
          <TextInput
            name="fullName"
            autoFocus
            icon={<IconUser size={20} />}
            pb="xs"
            placeholder={"Add Full Name"}
            {...signUpForm.getInputProps("userFullName")}
          />
          <TextInput
            name="email"
            icon={<IconMail size={20} />}
            pb="xs"
            placeholder={"Add Email"}
            {...signUpForm.getInputProps("username")}
          />
          <PasswordInput
            icon={<IconKey size={20} />}
            pb="xs"
            placeholder={"Add Password"}
            {...signUpForm.getInputProps("password")}
          />
          <PasswordInput
            icon={<IconKey size={20} />}
            pb="xs"
            placeholder={"Confirm Password"}
            {...signUpForm.getInputProps("confirmPassword")}
          />
          {!props.referrer && (
            <TextInput
              name="referrer"
              icon={<IconUsers size={20} />}
              pb="xl"
              placeholder={"Add Referral Code"}
              {...signUpForm.getInputProps("referrer")}
            />
          )}
          <Checkbox
            radius="xs"
            color="text/highlight"
            styles={{
              body: {
                border: "none"
              }
            }}
            sx={(th) => th.other.text["button-large"]}
            label={
              <>
                I Agree to the{" "}
                <Anchor
                  href="https://www.authoritive.com/cr-terms-and-conditions"
                  target="_blank"
                >
                  Terms of Service
                </Anchor>
                {" and "}
                <Anchor
                  href="https://www.authoritive.com/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </Anchor>
              </>
            }
            {...signUpForm.getInputProps("terms", {
              withError: true,
              withFocus: true,
              type: "checkbox"
            })}
          />
          {props.hasError && props.error instanceof Error && (
            <AlertComponent type={"error"} message={props.error.message} />
          )}
        </Paper>
        <AppButton
          size={"md"}
          label={"Create your free Account"}
          textStyle={"button-large"}
          textColor={"text/default"}
          color={"button/primary"}
          onClick={props.handleOnClick}
        />
      </Stack>
    </Stack>
  )
}
