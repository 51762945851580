function makeErrorObject(errObj) {
  return (
    errObj?._errors?.[0] ??
    Object.fromEntries(
      Object.entries(errObj)
        .filter((e) => e[0] !== "_errors")
        .map((e) => [e[0], makeErrorObject(e[1])])
    )
  )
}

function fromObject(obj, schema, options) {
  const parseRes = schema.safeParse(obj)
  if (parseRes.success) {
    console.info("✅ Valid env/config params:", parseRes.data)
    return parseRes.data
  }
  console.error(
    "❌ Invalid env/config params:\n",
    makeErrorObject(parseRes.error.format())
  )
  if (options?.message) console.info(options.message)
  process.exit(-1)
}

function fromImport(path, schema, options) {
  try {
    const data = require(path)
    return fromObject(data, schema)
  } catch (e) {
    console.error("❌ Could not import file:\n", path)
    process.exit(-1)
  }
}

function fromFile(path, schema, options) {
  try {
    const fs = require("fs")
    const data = fs.readFileSync(path)
    const dataJson = JSON.parse(data)
    return fromObject(dataJson, options)
  } catch (e) {
    console.error("❌ Could not open or read file:\n", path)
    if (options?.message) console.info(options.message)
    process.exit(-1)
  }
}

module.exports = {
  fromFile,
  fromObject,
  fromImport
}
