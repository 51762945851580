import {
  Button,
  LoadingOverlay,
  PasswordInput,
  Stack,
  TextInput,
  Title,
  Paper
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useMutation } from "@tanstack/react-query"
import { Auth } from "aws-amplify"
import { useStateContext } from "../AuthContext"
import { IconKey } from "@tabler/icons"
import { resetPasswordSchema } from "../../../../shared/formSchemas"
import Alert from "../../../../shared/components/Alert"
import AppText from "../../../../shared/components/AppText"

export default function ResetPasswordComponent() {
  const { goToState, data } = useStateContext("ResetPassword")

  const {
    mutate: resetPassword,
    isLoading,
    error,
    isError
  } = useMutation(
    (args: { code: string; password: string }) =>
      Auth.forgotPasswordSubmit(data.username, args.code, args.password),
    { onSuccess: () => goToState("Login", undefined) }
  )

  const resetPasswordForm = useForm({
    initialValues: {
      code: "",
      password: "",
      confirmPassword: ""
    },
    validate: zodResolver(resetPasswordSchema)
  })

  const handleResetPassword = () => {
    const valRes = resetPasswordForm.validate()
    if (valRes.hasErrors) return
    else {
      resetPassword({
        code: resetPasswordForm.values.code,
        password: resetPasswordForm.values.password
      })
    }
  }

  return (
    <Stack justify="space-between" sx={{ height: "100%" }}>
      <LoadingOverlay visible={isLoading} />
      <Button
        size="md"
        ml={"auto"}
        color="button/secondary"
        onClick={() => goToState("Login", undefined)}
      >
        <AppText type={"button-large"} color={"text/default"}>
          Back to login
        </AppText>
      </Button>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleResetPassword()
        }}
      >
        <Stack>
          <Title order={1} mb="xl">
            Reset your password
          </Title>
          <Paper p="xl">
            <Alert
              type={"info"}
              message={
                "A verification code has been sent to your email address"
              }
            />
            <TextInput
              autoFocus
              pb="xs"
              placeholder="Verification code"
              {...resetPasswordForm.getInputProps("code")}
            />
            <PasswordInput
              pb="xs"
              icon={<IconKey size={20} />}
              placeholder="New password"
              {...resetPasswordForm.getInputProps("password")}
            />
            <PasswordInput
              pb="xs"
              icon={<IconKey size={20} />}
              placeholder="Confirm new password"
              {...resetPasswordForm.getInputProps("confirmPassword")}
            />
            {isError && error instanceof Error && (
              <Alert type={"error"} message={error.message} />
            )}
          </Paper>
          <Button size="md" type={"submit"} color="button/primary">
            <AppText type={"button-large"} color={"text/default"}>
              Reset password
            </AppText>
          </Button>
        </Stack>
      </form>
    </Stack>
  )
}
