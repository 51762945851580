import { Avatar, Box, Center, Flex, Group, Stack, Title } from "@mantine/core"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { z } from "zod"
import { useMutation } from "@tanstack/react-query"
import { platformClient } from "../../../shared/services/PlatformClient"
import { keyframes } from "@mantine/core"

import MailChimpLogo from "./intuit-mailchimp-logo.jpg"
import AppButton from "../../../shared/components/AppButton"
import {
  IconAlertTriangle,
  IconChevronLeft,
  IconChevronRight,
  IconHome
} from "@tabler/icons"
import { AuthoritiveLogoSingleLetter } from "../../../shared/logo"
import AppText from "../../../shared/components/AppText"
import TransparentIcon from "../../../shared/components/TransparentIcon"

const MailchimpCallbackSchema = z.object({
  programId: z.string(),
  slug: z.string(),
  listId: z.string(),
  accountId: z.string()
})

const fadeIn = keyframes({ from: { opacity: 1 }, to: { opacity: 0 } })

function ArrowsLoader() {
  const animation = `${fadeIn} 1s infinite`

  return (
    <Stack>
      <Group spacing={0}>
        <Box sx={{ animation, animationDelay: "0s" }}>
          <IconChevronRight size={40} />
        </Box>
        <Box sx={{ animation, animationDelay: ".3s" }}>
          <IconChevronRight size={40} />
        </Box>
        <Box sx={{ animation, animationDelay: ".6s" }}>
          <IconChevronRight size={40} />
        </Box>
      </Group>
      <Group spacing={0}>
        <Box sx={{ animation, animationDelay: ".6s" }}>
          <IconChevronLeft size={40} />
        </Box>
        <Box sx={{ animation, animationDelay: ".3s" }}>
          <IconChevronLeft size={40} />
        </Box>
        <Box sx={{ animation, animationDelay: "0s" }}>
          <IconChevronLeft size={40} />
        </Box>
      </Group>
    </Stack>
  )
}

export default function MailchimpCallback() {
  const [isError, setIsError] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const code: string | null = searchParams.get("code")
  const state = searchParams.get("state")

  const stateParams = (() => {
    try {
      if (!state) return undefined
      return MailchimpCallbackSchema.parse(JSON.parse(atob(state)))
    } catch (e) {
      return undefined
    }
  })()

  const { mutate: setupMailchimp, isLoading: isConnecting } = useMutation(
    (args: { slug: string; code: string; listId: string; accountId: string }) =>
      platformClient.setupMailchimpInstance(args, true),
    {
      onSuccess: () => {
        if (stateParams?.programId) {
          return navigate(
            `/programs/${stateParams.programId}/publish?tab=integrations`
          )
        } else {
          return navigate("/")
        }
      },
      onError: () => {
        setIsError(true)
      }
    }
  )

  const mountCalled = useRef(false)

  useEffect(() => {
    if (!mountCalled.current) {
      console.log({ stateParams })
      mountCalled.current = true
      if (stateParams && code) {
        const { slug, listId, accountId } = stateParams
        setupMailchimp({
          slug,
          code,
          listId,
          accountId
        })
      } else {
        setIsError(true)
      }
    }
  }, [])

  return (
    <Center mih={"100vh"} w={"100%"}>
      <Stack justify={"center"} align={"center"}>
        <Flex align={"center"} justify={"center"} gap={"sm"}>
          <AuthoritiveLogoSingleLetter
            dark
            sx={{ width: 200, height: 200, borderRadius: 9999 }}
          />
          {isConnecting && !isError && <ArrowsLoader />}
          {isError && (
            <TransparentIcon color={"text/alert"} size={50}>
              <IconAlertTriangle size={40} />
            </TransparentIcon>
          )}
          <Avatar h={200} w={200} radius={9999} src={MailChimpLogo} />
        </Flex>
        {!isError && <Title order={3}>Connecting with Mailchimp...</Title>}
        {isError && (
          <Stack align={"center"} justify={"center"}>
            <AppText type={"heading-medium"} color={"text/alert"}>
              Something went wrong...
            </AppText>
            <Link to={"/"}>
              <AppButton
                px={"xl"}
                rightIcon={<IconHome size={17} />}
                size={"lg"}
                label={"Back to home"}
                textStyle={"button-large"}
                textColor={"text/default"}
                color={"button/secondary"}
              />
            </Link>
          </Stack>
        )}
      </Stack>
    </Center>
  )
}
