import { useStateContext } from "../AuthContext"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useForm, zodResolver } from "@mantine/form"
import { Auth } from "aws-amplify"
import {
  Anchor,
  Button,
  LoadingOverlay,
  Stack,
  TextInput,
  Paper,
  Title
} from "@mantine/core"
import { z } from "zod"

import Alert from "../../../../shared/components/Alert"
import AppText from "../../../../shared/components/AppText"
import ReactGA from "react-ga4"

const verifyAccountSchema = z.object({
  code: z
    .string()
    .min(6, { message: "Verification code is not properly formatted" })
})

export default function VerifyAccountComponent() {
  const { goToState, data } = useStateContext("VerifyAccount")

  const queryClient = useQueryClient()

  const form = useForm({
    initialValues: { code: "" },
    validate: zodResolver(verifyAccountSchema)
  })
  const {
    mutate: verifyAccount,
    isLoading: isVerifying,
    error: verifyAccountError
  } = useMutation(
    (args: { code: string }) => Auth.confirmSignUp(data.username, args.code),
    {
      onSuccess: () => {
        ReactGA.event({
          category: "user",
          action: "sign_up"
        })
        /** auto sign in after confirmation
         *  We need to poll until the session is valid to invalidate session cache
         */
        const maxRetries = 10
        let retryNo = 0
        const pollInterval = setInterval(async () => {
          const session = await Auth.currentSession().catch(() => undefined)
          if (session) {
            queryClient.invalidateQueries(["session"])
            clearInterval(pollInterval)
            return
          }
          retryNo++
          if (retryNo > maxRetries) {
            clearInterval(pollInterval)
            location.reload()
          }
        }, 500)
      }
    }
  )

  const {
    isSuccess: isResendSuccess,
    mutate: resendCode,
    isLoading: isResending,
    error: resendCodeError
  } = useMutation(() => Auth.resendSignUp(data.username))

  const handleVerify = () => {
    const valRes = form.validate()
    if (valRes.hasErrors) return
    verifyAccount({ code: form.values.code })
  }

  return (
    <Stack justify="space-between" sx={{ height: "100%" }}>
      <LoadingOverlay visible={isVerifying || isResending} />
      <Button
        size="md"
        ml={"auto"}
        color="button/secondary"
        onClick={() => goToState("Login", undefined)}
      >
        <AppText type={"button-large"} color={"text/default"}>
          Back to login
        </AppText>
      </Button>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleVerify()
        }}
      >
        <Stack>
          <Title order={1} mb="xl">
            Verify your account
          </Title>
          <Paper p="xl">
            {isResendSuccess && (
              <Alert
                type="info"
                message="Resent code successfully. Check your inbox."
              />
            )}
            <TextInput
              autoFocus
              pb="xs"
              placeholder={"Enter verification code"}
              {...form.getInputProps("code")}
            />
            <Anchor
              onClick={() => resendCode()}
              sx={(th) => th.other.text["button-large"]}
            >
              Request a new code
            </Anchor>
            <>
              {resendCodeError && resendCodeError instanceof Error && (
                <Alert type={"error"} message={resendCodeError.message} />
              )}
              {verifyAccountError && verifyAccountError instanceof Error && (
                <Alert type="error" message={verifyAccountError.message} />
              )}
            </>
          </Paper>
          <Button size="md" type={"submit"} color="button/primary">
            <AppText type={"button-large"} color={"text/default"}>
              Verify Account
            </AppText>
          </Button>
        </Stack>
      </form>
    </Stack>
  )
}
