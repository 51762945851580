import {z} from "zod"
import { GQLZodPrimitiveType, GQLZodSchema } from "./GQLZodSchema"
import { SimpleGraphQLClient } from "./SimpleGraphQLClient"
import { GraphQLRequest } from "./generateGraphQLRequest"

type NoExtra<T,R> = {[key in keyof T]:key extends keyof R?T[key]:never}

export type GQLZodSelector<ZT extends z.ZodType> = Selector<ZT>

type FindMatchinType<Typename extends string, Opts extends z.ZodObject<{"__typename":z.ZodType} & z.ZodRawShape>[]>
  = Opts extends [any] ? Opts[0] :
    // eslint-disable-next-line prettier/prettier
    Opts extends [infer First, ...infer Rest extends z.ZodObject<{"__typename":z.ZodType} & z.ZodRawShape>[]] ? First extends z.ZodObject<{"__typename":z.ZodLiteral<Typename>}> ? First
    : FindMatchinType<Typename, Rest> : never

type Selector<ZT extends z.ZodType> = ZT extends GQLZodPrimitiveType
  ? true
  : ZT extends
      | z.ZodArray<infer B>
      | z.ZodOptional<infer B>
      | z.ZodNullable<infer B>
  ? Selector<B>
  : ZT extends z.ZodDiscriminatedUnion<"__typename", infer Opts extends z.ZodObject<{"__typename":z.ZodType} & z.ZodRawShape>[]>
  ? //ObjectFromEntries<DistributeSelector<Opts[number]>>
            {$on:{[key in Opts[number]["shape"]["__typename"]["_output"] & string]: Selector<FindMatchinType<key, Opts>> } }
  : ZT extends z.ZodObject<infer Shape>
  ? // TODO Check of at least one key
  { [key in keyof Shape]?: Selector<Shape[key]>}
  : never

type ObjValues<Obj> =  Obj[keyof Obj]

type T2<T> = T extends (infer B | undefined) ? "optional" :"not_opt"
type T1t = {id:string, name?:string}
type T1tt = Required<T1t>["name"]

export type ApplySelector<T, Sel> = Sel extends true
  ? T
  : T extends Array<infer InnerT>
  ? Array<ApplySelector<InnerT, Sel>>
  : keyof Sel extends keyof T ? { [key in keyof Sel]: T[key] extends Required<T>[key] ?  ApplySelector<T[key], Sel[key]> : ApplySelector<T[key], Sel[key]> | undefined }
  : Sel extends {$on:Record<string, unknown>} ? _OnSelector<T,Sel["$on"]>
  : never

type _OnSelector<T,Sel extends Record<string, unknown>> = ObjValues<{[key in keyof Sel]:_DistOnSelector<T,[key & string,Sel[key]]>}>
type _DistOnSelector<T,Sel extends [string,unknown]> = T extends {__typename:Sel[0]} ? ApplySelector<T, Sel[1]> : never

type RequestType<F extends z.ZodFunction<any, any>> = <
  S extends Selector<F["_def"]["returns"]>
>(
  args: Parameters<z.infer<F>>[0],
  selector: NoExtra<S,Selector<F["_def"]["returns"]>>
) => Promise<ApplySelector<ReturnType<z.infer<F>>, S>>

type ClientType<S extends GQLZodSchema> = {
  [q in keyof S["Query"]]: RequestType<S["Query"][q]>
} &
  { [q in keyof S["Mutation"]]: RequestType<S["Mutation"][q]> }

export type TypedGraphQlRequest<S extends GQLZodSchema> =
    ObjValues<({ [q in keyof S["Mutation"]]: { opType:"mutation", opName:q, args:Parameters<RequestType<S["Mutation"][q]>>[0], result: Awaited<ReturnType<RequestType<S["Mutation"][q]>>>}  }
&  { [q in keyof S["Query"]]: { opType:"query", opName:q, args:Parameters<RequestType<S["Query"][q]>>[0],result: Awaited<ReturnType<RequestType<S["Query"][q]>>> }  })>

export function makeClientClass<S extends GQLZodSchema>(
  s: S
): {
  new (
    ...args: ConstructorParameters<typeof SimpleGraphQLClient>
  ): SimpleGraphQLClient & ClientType<S>
} {
  const clazz = class extends SimpleGraphQLClient {} as {
    new (
      ...args: ConstructorParameters<typeof SimpleGraphQLClient>
    ): SimpleGraphQLClient & ClientType<S>
  }

  Object.entries(s.Query).forEach((q) => {
    Object.defineProperty(clazz.prototype, q[0], {
      value: function (args: any, selector: any) {
        const argsSchema = (s.Query[q[0]].parameters() as z.ZodTuple).items[0]
        if (argsSchema){
          args = argsSchema.parse(args)
        }
        const req: GraphQLRequest = {
          opType: "query",
          opName: q[0],
          selector,
          args
        }
        return this.makeRequest(req)
      }
    })
  })

  Object.entries(s.Mutation).forEach((m) => {
    Object.defineProperty(clazz.prototype, m[0], {
      value: function (args: any, selector: any) {
        const argsSchema = (s.Mutation[m[0]].parameters() as z.ZodTuple).items[0]
        if (argsSchema){
          args = argsSchema.parse(args)
        }
        const req: GraphQLRequest = {
          opType: "mutation",
          opName: m[0],
          selector,
          args
        }
        return this.makeRequest(req)
      }
    })
  })

  return clazz
}
