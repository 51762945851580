// Mantine imports
import {
  AppShell,
  Container,
  Card,
  Stack,
  Title,
  Group,
  Divider
} from "@mantine/core"

// Components import
import LoginComponent from "../../components/Login"
import SignUpComponent from "../../components/SignUp"
import {
  AuthContextProvider,
  useAuthContext
} from "../../components/AuthContext"
import ForgotPasswordComponent from "../../components/ForgotPassword"
import VerifyAccountComponent from "../../components/VerifyAccount"
import ResetPasswordComponent from "../../components/ResetPassword"
import LogoInlineWithText from "../../../../shared/logo"
import AppText from "../../../../shared/components/AppText"

function AuthComponent() {
  const { currentState } = useAuthContext()
  if (currentState === "Login") return <LoginComponent />
  if (currentState === "SignUp") return <SignUpComponent />
  if (currentState === "VerifyAccount") return <VerifyAccountComponent />
  if (currentState === "ForgotPassword") return <ForgotPasswordComponent />
  if (currentState === "ResetPassword") return <ResetPasswordComponent />
  return <div>Unhandled {currentState}</div>
}

function MobileFriendlyAuthPage(props: { title: string; description: string }) {
  return (
    <Stack
      justify="space-between"
      spacing={"xl"}
      sx={(th) => ({
        [th.fn.largerThan("md")]: {
          display: "none"
        },
        height: "100%"
      })}
    >
      <LogoInlineWithText />
      <Stack spacing={"xl"} pb={"xl"}>
        <Title
          color="bg/primary-l3"
          sx={(th) => th.other.text["heading-medium"]}
          pt={"lg"}
        >
          {props.title}
        </Title>
        <AppText type={"body-medium"} color={"bg/primary-l1"}>
          {props.description}
        </AppText>
        <Divider size="xl" sx={{ width: 64 }} color="primitive/yellow/50" />
        <Card bg={"bg/primary-l1"} radius="xl" sx={{ width: "100%" }}>
          <AuthComponent />
        </Card>
      </Stack>
    </Stack>
  )
}

export default function AuthPage() {
  const title = "Create interactive messaging programs"
  const description =
    "Generate revenue by creating customized, interactive experiences that are delivered directly to your audience via text or other messaging platforms. Combine audio, video and text to deliver a highly engaging program, course or experience directly to your audience."
  const secondaryDescription =
    "Easy to create, deliver, and monetize, with no downloads, algorithms, or noise."

  return (
    <AuthContextProvider>
      <AppShell
        padding={"xl"}
        sx={(th) => ({
          backgroundColor: th.colors["bg/inverted-l1"][0]
        })}
      >
        <MobileFriendlyAuthPage title={title} description={description} />
        <Group
          align={"start"}
          position={"apart"}
          sx={(th) => ({
            [th.fn.smallerThan("md")]: {
              display: "none"
            },
            height: "100%",
            flexGrow: 7
          })}
        >
          <Stack
            align="flex-start"
            justify="space-between"
            sx={{
              flexGrow: 10,
              width: "50%",
              height: "100%"
            }}
          >
            <LogoInlineWithText />
            <Container sx={{ width: 412 }} m={0}>
              <Stack p="md">
                <Title
                  mb="xl"
                  color="bg/primary-l3"
                  sx={(th) => th.other.text["heading-xlarge"]}
                >
                  {title}
                </Title>
                <AppText type={"body-large"} color={"bg/primary-l1"}>
                  {description}
                </AppText>
                <AppText type={"body-large"} color={"bg/primary-l1"}>
                  {secondaryDescription}
                </AppText>
                <Divider
                  my="xl"
                  size="xl"
                  sx={{ width: 64 }}
                  color="primitive/yellow/50"
                />
              </Stack>
            </Container>
          </Stack>
          <Container size={"md"} m={0} sx={{ height: "100%", width: 497 }}>
            <Card
              bg={"bg/primary-l1"}
              radius="xl"
              p="xl"
              sx={{ height: "100%" }}
            >
              <AuthComponent />
            </Card>
          </Container>
        </Group>
      </AppShell>
    </AuthContextProvider>
  )
}
