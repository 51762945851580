export const businessSubjects = [
  "Personal Development",
  "Business & Marketing",
  "Finance & Investing",
  "Software & Technology",
  "Health & Wellness",
  "Fitness & Nutrition",
  "Family & Parenting",
  "Fashion & Beauty",
  "Arts & Crafting",
  "Design & UX",
  "Music & Instruments",
  "Photo & Video",
  "Food & Drink",
  "Acting & Entertainment",
  "Academics & Languages",
  "Spirituality & Worship"
]
