import React, { useEffect, useRef } from "react"
import Authenticated from "./modules/login/pages"
import { AppRouter } from "./modules"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { MantineProvider } from "@mantine/core"
import { platformTheme } from "./shared/theme"
import { BrowserRouter } from "react-router-dom"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { NotificationsProvider } from "@mantine/notifications"
import { JourneyProvider } from "./shared/components/Journey/Journey"
import { platformClient } from "./shared/services/PlatformClient"
import ScreenSizeGuard from "./shared/components/ScreenSizeGuard"
import ActionHandler from "./shared/components/ActionHandler"

export const globalQueryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: true }
  }
})

export const whoAmIRequest = {
  queryKey: ["whoami"],
  staleTime: 60000,
  queryFn: () =>
    platformClient.whoami(
      {},
      { email: true, name: true, activeAccountId: true }
    )
} as const

function App() {
  return (
    <QueryClientProvider client={globalQueryClient}>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={platformTheme}>
        <NotificationsProvider>
          <BrowserRouter>
            <Authenticated>
              <ActionHandler>
                <ScreenSizeGuard>
                  <AppRouter />
                </ScreenSizeGuard>
              </ActionHandler>
            </Authenticated>
          </BrowserRouter>
        </NotificationsProvider>
      </MantineProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
