import { jsonToGraphQLQuery } from "json-to-graphql-query"
import { filterObject, mapObject } from "./generateGraphQLSchema"

type GQLZodSelector =
  | true
  | { $on: { [key: string]: GQLZodSelector } }
  | { [key: string]: GQLZodSelector }
type JsonSelector = true | JsonSelector[] | { [key: string]: JsonSelector }

function adaptSelector(sel: GQLZodSelector): JsonSelector {
  if (sel === true) return true
  return Object.fromEntries(
    Object.entries(sel).map(([key, val]) => {
      if (key === "$on")
        return [
          "__on",
          Object.entries(sel["$on"]).map(([tn, sel]) => ({
            __typeName: tn,
            ...(adaptSelector(sel as any) as object)
          }))
        ]
      return [key, adaptSelector(val)]
    })
  )
}

export interface GraphQLRequest {
  opType: "query" | "mutation"
  opName: string
  args: Record<string, unknown>
  selector: GQLZodSelector
}
export interface RequestFormatOptions {
  stringifyNestedObjects?: boolean
  parseDates?: boolean
}

export function generateGraphQLRequest(
  request: GraphQLRequest,
  options?: { alias?: string } & RequestFormatOptions
) {
  const reqObject = generateGraphQLRequestObject(request, options)
  const req = jsonToGraphQLQuery(reqObject, { pretty: false })
  return req
}

function formatArgs(
  args: Record<string, unknown>,
  options?: RequestFormatOptions
) {
  return mapObject(args, (v) => {
    if (v === null) return null
    if (v instanceof Date) return v.toISOString()
    if (options?.stringifyNestedObjects) {
      if (Array.isArray(v) || typeof v === "object") return JSON.stringify(v)
    }
    return v
  })
}

function generateGraphQLRequestObject(
  request: GraphQLRequest,
  options?: { alias?: string; stringifyNestedObjects?: boolean }
) {
  const { opType, opName, args, selector } = request
  const alias = options?.alias
  const adaptedSelector = adaptSelector(selector)
  const reqObject = {
    [opType]: {
      [alias ?? opName]: {
        __aliasFor: alias && opName,
        __args: filterObject(formatArgs(args, options), (v) => v !== undefined),
        ...(adaptedSelector !== true ? adaptedSelector : {})
      }
    }
  }
  return reqObject
}

export function stitchRequests(
  requests: Record<string, GraphQLRequest>,
  options?: { stringifyNestedObjects?: boolean }
) {
  const reqEntries = Object.entries(requests)
  if (reqEntries.length === 0)
    throw new Error("No queries/mutation where passed")
  if (!reqEntries.every(([, v]) => v.opType === reqEntries[0][1].opType))
    throw new Error("Can only stitch requests of same type")
  const opType = reqEntries[0][1].opType
  const stichedRequestObject = reqEntries
    .map(([key, req]) =>
      generateGraphQLRequestObject(req, { alias: key, ...options })
    )
    .reduce(
      (p, c) => ({
        [opType]: { ...p.query, ...c.query, ...p.mutation, ...c.mutation }
      }),
      {}
    )
  return jsonToGraphQLQuery(stichedRequestObject, { pretty: false })
}
