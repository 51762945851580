import { z } from "zod"
import { defineSchema } from "@authoritive/gql-zod/dist/GQLZodSchema"
import {
  ChannelRefSchema,
  ECommerceGqlZodSchemaV3
} from "@authoritive/ecommerce-service/src/ecommerce.gqlzodv3"
import { AccountsGqlZodSchema } from "@authoritive/accounts-service/src/accounts.gqlzod"
import { PlatformEventSchema } from "@authoritive/platform-eventbus/src/EventSchema"

/*function pickKeys<
  R extends Record<string, unknown>,
  S extends { [key in keyof R]?: true }
>(rec: R, mask: S): Pick<R, keyof S & keyof R> {
  return Object.fromEntries(
    Object.entries(rec).filter(([k, v]) => mask[k])
  ) as any
}*/

const accountsSchema = {
  types: AccountsGqlZodSchema.types,
  Query: {
    whoami: AccountsGqlZodSchema.Query.whoami,
    getAccountUser: AccountsGqlZodSchema.Query.getAccountUser,
    getAccount: z
      .function()
      .args()
      .returns(AccountsGqlZodSchema.Query.getAccount.returnType()),
    getActiveAccountPlan: z
      .function()
      .args()
      .returns(AccountsGqlZodSchema.Query.getActiveAccountPlan.returnType()),
    listAccountPlanTemplates: z
      .function()
      .args()
      .returns(
        AccountsGqlZodSchema.Query.listAccountPlanTemplates.returnType()
      ),
    listAccountUsers: z
      .function()
      .args()
      .returns(AccountsGqlZodSchema.Query.listAccountUsers.returnType()),
    listUserAccounts: AccountsGqlZodSchema.Query.listUserAccounts,
    searchUserAccounts: AccountsGqlZodSchema.Query.searchUserAccounts,
    getStripeAccount: AccountsGqlZodSchema.Query.getStripeAccount,
    listStripePayments: AccountsGqlZodSchema.Query.listStripePayments,
    getStripeCustomer: AccountsGqlZodSchema.Query.getStripeCustomer
  },
  Mutation: {
    createStripeAccount: AccountsGqlZodSchema.Mutation.createStripeAccount,
    createConnectStripeAccountLink:
      AccountsGqlZodSchema.Mutation.createConnectStripeAccountLink,
    selectPlan: AccountsGqlZodSchema.Mutation.selectAccountPlan,
    createStripeCustomerPortalLink:
      AccountsGqlZodSchema.Mutation.createStripeCustomerPortalLink,
    addUserToAccount: AccountsGqlZodSchema.Mutation.addUserToAccount,
    updateUserAccount: AccountsGqlZodSchema.Mutation.updateUserAccount,
    updateAccount: AccountsGqlZodSchema.Mutation.updateAccount,
    updateStripeAccountId: AccountsGqlZodSchema.Mutation.updateStripeAccountId,
    switchUserActiveAccount:
      AccountsGqlZodSchema.Mutation.switchUserActiveAccount
  }
}

const ecommerceSchema = {
  types: {
    Order: ECommerceGqlZodSchemaV3.types.Order,
    OrderAttributes: ECommerceGqlZodSchemaV3.types.OrderAttributes,
    Bulk: ECommerceGqlZodSchemaV3.types.Bulk,
    Gift: ECommerceGqlZodSchemaV3.types.Gift,
    Basic: ECommerceGqlZodSchemaV3.types.Basic,
    Payment: ECommerceGqlZodSchemaV3.types.Payment,
    Customer: ECommerceGqlZodSchemaV3.types.Customer,
    PaginatedOrders: ECommerceGqlZodSchemaV3.types.PaginatedOrders,
    Product: ECommerceGqlZodSchemaV3.types.Product,
    Pricing: ECommerceGqlZodSchemaV3.types.Pricing,
    Discount: ECommerceGqlZodSchemaV3.types.Discount,
    PromoCode: ECommerceGqlZodSchemaV3.types.PromoCode,
    PaginatedPromoCode: ECommerceGqlZodSchemaV3.types.PaginatedPromoCode,
    BulkTier: ECommerceGqlZodSchemaV3.types.BulkTier,
    Listing: ECommerceGqlZodSchemaV3.types.Listing,
    AnalyticsConfig: ECommerceGqlZodSchemaV3.types.AnalyticsConfig,
    PixelAnalyticsConfig: ECommerceGqlZodSchemaV3.types.PixelAnalyticsConfig,
    GoogleAdsAnalyticsConfig:
      ECommerceGqlZodSchemaV3.types.GoogleAdsAnalyticsConfig,
    EmailMarketingConfig: ECommerceGqlZodSchemaV3.types.EmailMarketingConfig,
    KlaviyoConfig: ECommerceGqlZodSchemaV3.types.KlaviyoConfig,
    ConvertKitConfig: ECommerceGqlZodSchemaV3.types.ConvertKitConfig,
    MailChimpConfig: ECommerceGqlZodSchemaV3.types.MailChimpConfig,
    ListingCustomization: ECommerceGqlZodSchemaV3.types.ListingCustomization,
    NavLink: ECommerceGqlZodSchemaV3.types.NavLink,
    Fulfillment: ECommerceGqlZodSchemaV3.types.Fulfillment,
    ProgramFulfillment: ECommerceGqlZodSchemaV3.types.ProgramFulfillment,
    VoidFulfillment: ECommerceGqlZodSchemaV3.types.VoidFulfillment,
    HttpFulfillment: ECommerceGqlZodSchemaV3.types.HttpFulfillment
  },
  Query: {
    listPromoCodes: ECommerceGqlZodSchemaV3.Query.listPromoCodes,
    listOrders: ECommerceGqlZodSchemaV3.Query.listOrders
  },
  Mutation: {
    updateProduct: ECommerceGqlZodSchemaV3.Mutation.updateProduct,
    createListing: ECommerceGqlZodSchemaV3.Mutation.createListing,
    updateListing: ECommerceGqlZodSchemaV3.Mutation.updateListing,
    createPromoCode: ECommerceGqlZodSchemaV3.Mutation.createPromoCode,
    updatePromoCode: ECommerceGqlZodSchemaV3.Mutation.updatePromoCode,
    deletePromoCode: ECommerceGqlZodSchemaV3.Mutation.deletePromoCode,
    setupMailchimpInstance:
      ECommerceGqlZodSchemaV3.Mutation.setupMailchimpInstance
  }
}

const ContentNodeSchema = z.object({
  content: z.string()
})

const Attachment = z.object({
  name: z.string(),
  type: z.enum(["image", "video", "audio", "file"]),
  url: z.string(),
  thumbnail: z.string().optional(),
  size: z.number().optional()
})

const FlowNodeData = z.object({
  type: z.enum(["content"]),
  content: z.string(),
  attachments: z.array(Attachment)
})

export const SubmissionChannelsSchema = z.object({
  sms: ChannelRefSchema.optional(),
  whatsapp: ChannelRefSchema.optional(),
  msteams: ChannelRefSchema.optional()
})

export const SubmissionSchema = z.object({
  id: z.string(),
  programId: z.string(),
  accountId: z.string(),
  createdAt: z.date(),
  createdBy: z.string(),
  status: z.enum([
    "UNDER_REVIEW",
    "REJECTED",
    "AWAITING_PROVISIONING",
    "AWAITING_DEPLOYMENT",
    "DEPLOYED"
  ]),
  channels: SubmissionChannelsSchema.optional(),
  deploymentId: z.string().optional(),
  notes: z.string().optional()
})

export const FlowNodeSchema = z.object({
  id: z.string(),
  data: FlowNodeData
})

const FlowEdgeDataSchema = z.object({
  type: z.enum(["reply", "link"]),
  reply: z.string()
})

export const FlowEdgeSchema = z.object({
  from: z.string(),
  to: z.string(),
  data: FlowEdgeDataSchema
})

export const FlowElementSchema = z.union([FlowNodeSchema, FlowEdgeSchema])

const FlowSchema = z.object({
  elements: z.record(FlowElementSchema),
  version: z.string()
})

export const ImportProgramSchema = z.object({
  title: z.string(),
  topics: z
    .object({
      name: z.string(),
      elements: FlowElementSchema.array()
    })
    .array()
})

export const TopicSchema = z.object({
  id: z.string(),
  programId: z.string(),
  name: z.string(),
  firstMessageTime: z.string().optional(),
  cadenceOrder: z.number().optional(),
  flow: FlowSchema
})

const CreateTopicSchema = TopicSchema.omit({ id: true, flow: true })
const UpdateTopicSchema = TopicSchema.pick({ id: true }).merge(
  TopicSchema.pick({
    name: true,
    firstMessageTime: true,
    cadenceOrder: true
  }).partial()
)

const ProgramStatusSchema = z.enum([
  "PUBLISHED",
  "DRAFT",
  "UNDER_REVIEW",
  "DECLINED",
  "ARCHIVED"
])

const DayOfWeekSchema = z.enum([
  "MON",
  "TUE",
  "WED",
  "THU",
  "FRI",
  "SAT",
  "SUN"
])

export const ProgramSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    objective: z.string(),
    cadence: z.array(DayOfWeekSchema),
    durationDays: z.number(),
    startDate: DayOfWeekSchema.nullable(),
    firstMessageTime: z.string(),
    menuMessage: z.string().optional(),
    coverPhotoUrl: z.string().optional(),
    updatedAt: z.date(),
    productId: z.string(),
    status: ProgramStatusSchema
  })
  .extend({
    product: ecommerceSchema.types.Product,
    submission: SubmissionSchema.optional()
  })

const CreateProgramSchema = ProgramSchema.pick({ name: true }).strip()

const UpdateProgramSchema = ProgramSchema.pick({ id: true }).merge(
  ProgramSchema.pick({
    name: true,
    objective: true,
    cadence: true,
    durationDays: true,
    startDate: true,
    firstMessageTime: true
  }).partial()
)

const PresignedUrlSchema = z.object({
  uploadUrl: z.string(),
  fileUrl: z.string()
})

const SourceContentInfoSchema = z.object({
  title: z.string(),
  description: z.string(),
  url: z.string(),
  imageUrl: z.string(),
  contentInfo: z.string()
})

const ValidateSourceContentOutputSchema = z.object({
  error: z.string().nullish(),
  info: SourceContentInfoSchema.nullish()
})
/*
 {
    "interv": "month",
    "bucket_start": "2023-02-01T00:00:00.000Z",
    "bucket_end": "2023-03-01T00:00:00.000Z",
    "program_id": "01GTKZ4J30DXAV9TCJGWE3E16T",
    "program_name": "My Test Course with Christos",
    "orders": 1,
    "promo_code": null,
    "amount": 36000,
    "units": 9,
    "avg_initial_price": 45000,
    "avg_final_price": 36000
}
 */
const SalesReportSchema = z.object({
  interv: z.enum(["year", "quarter", "month", "week", "day", "hour"]),
  bucket_start: z.date(),
  bucket_end: z.date(),
  program_id: z.string().nullish(),
  program_name: z.string().nullish(),
  account_id: z.string().nullish(),
  orders: z.number(),
  promo_code: z.string().nullish(),
  amount: z.number().nullish(),
  units: z.number().nullish(),
  avg_initial_price: z.number().nullish(),
  avg_final_price: z.number().nullish()
})

const PurchaseReportRow = z.object({
  id: z.string(),
  email: z.string(),
  programId: z.string(),
  programName: z.string(),
  type: z.enum(["Basic", "Gift", "Bulk"]),
  quantity: z.number(),
  promoCode: z.string().optional(),
  finalAmount: z.number(),
  initialAmount: z.number(),
  date: z.date()
})

const PurchaseReportSchema = z.object({
  total: z.number(),
  data: PurchaseReportRow.array()
})

export const PlatformGqlSchema = defineSchema({
  types: {
    Program: ProgramSchema,
    Topic: TopicSchema,
    Flow: FlowSchema,
    SalesReport: SalesReportSchema,
    PurchaseReport: PurchaseReportSchema,
    PurchaseReportRow: PurchaseReportRow,
    ContentNode: ContentNodeSchema,
    ProgramStatus: ProgramStatusSchema,
    DayOfWeek: DayOfWeekSchema,
    PresignedUrl: PresignedUrlSchema,
    Submission: SubmissionSchema,
    ChannelRef: ChannelRefSchema,
    SubmissionChannels: SubmissionChannelsSchema,
    ValidateSourceContentOutput: ValidateSourceContentOutputSchema,
    SourceContentInfo: SourceContentInfoSchema,
    ...ecommerceSchema.types,
    ...accountsSchema.types
  },
  Query: {
    findProgram: z
      .function()
      .args(z.object({ id: z.string() }))
      .returns(ProgramSchema),
    listPrograms: z
      .function()
      .args(z.object({}))
      .returns(z.array(ProgramSchema)),
    findTopic: z
      .function()
      .args(z.object({ id: z.string() }))
      .returns(TopicSchema),
    listSubmissions: z
      .function()
      .args(z.object({ programId: z.string(), limit: z.number().optional() }))
      .returns(SubmissionSchema.array()),
    listTopics: z
      .function()
      .args(z.object({ programId: z.string() }))
      .returns(z.array(TopicSchema)),
    validateContentSource: z
      .function()
      .args(z.object({ source: z.string() }))
      .returns(ValidateSourceContentOutputSchema),

    getSalesReport: z
      .function()
      .args(
        z.object({
          startDate: z.date().optional(),
          endDate: z.date().optional(),
          programId: z.string().optional(),
          timezone: z.string()
        })
      )
      .returns(SalesReportSchema.array()),

    getPurchaseReport: z
      .function()
      .args(
        z.object({
          startDate: z.date().optional(),
          endDate: z.date().optional(),
          programId: z.string().optional(),
          timezone: z.string(),
          offset: z.number().optional(),
          limit: z.number().optional()
        })
      )
      .returns(PurchaseReportSchema),
    ...ecommerceSchema.Query,
    ...accountsSchema.Query
  },
  Mutation: {
    createProgram: z
      .function()
      .args(CreateProgramSchema)
      .returns(ProgramSchema),
    addMailchimpToListing: z
      .function()
      .args(z.object({ code: z.string(), slug: z.string() }))
      .returns(z.object({ programId: z.string() })),
    updateProgram: z
      .function()
      .args(UpdateProgramSchema)
      .returns(ProgramSchema),
    archiveProgram: z
      .function()
      .args(z.object({ id: z.string() }))
      .returns(ProgramSchema),
    unarchiveProgram: z
      .function()
      .args(z.object({ id: z.string() }))
      .returns(ProgramSchema),
    submitForReview: z
      .function()
      .args(z.object({ programId: z.string() }))
      .returns(SubmissionSchema),
    createPreSignedUploadUrl: z
      .function()
      .args(
        z.object({
          filename: z.string(),
          contentType: z.string(),
          shorten: z.boolean().optional()
        })
      )
      .returns(PresignedUrlSchema),
    createTopic: z.function().args(CreateTopicSchema).returns(TopicSchema),
    updateTopic: z.function().args(UpdateTopicSchema).returns(TopicSchema),
    deleteTopic: z
      .function()
      .args(TopicSchema.pick({ id: true, programId: true }))
      .returns(z.boolean().optional()),
    updateFlow: z
      .function()
      .args(
        z.object({
          topicId: z.string(),
          version: z.string(), // current version of flow
          elements: z.record(FlowElementSchema), // elements to add or update
          remove: z.array(z.string()) // elements to remove
        })
      )
      .returns(TopicSchema),
    emitEvent: z
      .function()
      .args(z.object({ event: PlatformEventSchema }))
      .returns(z.boolean().optional()),

    selectAccountPlan: z
      .function()
      .args(
        z.object({ id: z.string(), billing: z.enum(["monthly", "yearly"]) })
      )
      .returns(z.boolean().nullish()),

    importProgram: z
      .function()
      .args(z.object({ program: z.record(z.any()) }))
      .returns(z.string()),
    ...ecommerceSchema.Mutation,
    ...accountsSchema.Mutation
  }
})
