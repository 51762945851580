import { Center, Group, Loader, packSx, Sx } from "@mantine/core"
import LogoInlineWithText from "../../logo"

type pageLoaderProps = {
  withLogo?: boolean
  sx?: Sx | Sx[]
}

export default function PageLoader(props: pageLoaderProps) {
  return (
    <Center
      sx={[
        (th) => ({
          width: "100%",
          height: "100vh",
          backgroundColor: th.colors["bg/primary-l1"][0]
        }),
        ...packSx(props.sx)
      ]}
    >
      <Group align={"center"}>
        {props.withLogo && <LogoInlineWithText dark />}
        <Loader size={"sm"} color={"bg/black"} />
      </Group>
    </Center>
  )
}
