import { ReactNode } from "react"
import { Amplify, Auth } from "aws-amplify"
import { env } from "../../../env/env"
import { useQuery } from "@tanstack/react-query"
import LoginPage from "./AuthPage"
import PageLoader from "../../../shared/components/PageLoader"

Amplify.configure({
  aws_cognito_region: env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: env.REACT_APP_USER_POOLS_ID,
  aws_user_pools_web_client_id: env.REACT_APP_USER_POOLS_WEB_CLIENT_ID
})

export default function Authenticated(props: { children: ReactNode }) {
  const {
    data: currentSession,
    isLoading,
    error
  } = useQuery(["session"], () => Auth.currentSession(), {
    retry: false,
    staleTime: 0,
    cacheTime: 0
  })
  if (isLoading) return <PageLoader withLogo />
  if (error) return <LoginPage />
  if (currentSession) return <>{props.children}</>
  return <div>Never</div>
}
