import { z } from "zod"
import { defineSchema } from "@authoritive/gql-zod/dist/GQLZodSchema"

export const ChannelRefSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  type: z.enum(["BandwidthChannel", "TwilioChannel", "MsTeamsChannel"]),
  data: z.record(z.any()).optional()
})

const DiscountType = z.enum(["Fixed", "Percentage"])

const Discount = z.object({
  amount: z.number(),
  type: DiscountType
})
const BulkTier = z.object({
  low: z.number(),
  discount: Discount
})
const Pricing = z.object({
  currency: z.string().nullish(),
  basePrice: z.number(),
  discount: Discount.nullish(),
  bulkTiers: z.array(BulkTier).nullish()
})
const VoidFulfillment = z.object({
  __typename: z.literal("VoidFulfillment")
})

const HttpFulfillment = z.object({
  __typename: z.literal("HttpFulfillment"),
  endpoint: z.string()
})

const FulfillmentChannels = z.object({
  sms: ChannelRefSchema.optional(),
  whatsapp: ChannelRefSchema.optional(),
  msteams: ChannelRefSchema.optional()
})

const ProgramFulfillment = z.object({
  __typename: z.literal("ProgramFulfillment"),
  deploymentId: z.string(),
  channels: FulfillmentChannels
})

const Fulfillment = z.discriminatedUnion("__typename", [
  VoidFulfillment,
  HttpFulfillment,
  ProgramFulfillment
])

const NavLink = z.object({
  label: z.string(),
  url: z.string()
})

const PromoCode = z.object({
  id: z.string(),
  productId: z.string(),
  code: z.string(),
  discount: Discount,
  validUntil: z.date().nullable(),
  limit: z.number().nullable(),
  uses: z.number()
})

const PaginatedPromoCode = z.object({
  nextToken: z.string().optional(),
  items: PromoCode.array()
})

const FontFamily = z.enum(["Standard", "Modern", "Classic", "Friendly"])

const ListingCustomization = z.object({
  title: z.string(),
  description: z.string(),
  heroImageUrl: z.string(),
  navLinks: z.array(NavLink),
  primaryColor: z.string(),
  backgroundColor: z.string(),
  font: FontFamily
})

const PixelAnalyticsConfigSchema = z.object({
  enabled: z.boolean().nullish(),
  pixelId: z.string().nullish(),
  domainVerificationCode: z.string().nullish(),
  conversionsApiAccessToken: z.string().nullish()
})

const GoogleAdsAnalyticsConfigSchema = z.object({
  enabled: z.boolean().nullish(),
  conversionTrackingTag: z.string().nullish()
})

const AnalyticsConfigSchema = z.object({
  googleAds: GoogleAdsAnalyticsConfigSchema.nullish(),
  pixel: PixelAnalyticsConfigSchema.nullish()
})

const KlaviyoConfigSchema = z.object({
  enabled: z.boolean().nullish(),
  privateApiKey: z.string().nullish()
})

const ConvertKitConfigSchema = z.object({
  enabled: z.boolean().nullish(),
  apiSecretKey: z.string().nullish()
})

const MailChimpConfigSchema = z.object({
  enabled: z.boolean().nullish(),
  dc: z.string().nullish(),
  accessToken: z.string().nullish(),
  listId: z.string().nullish(),
  storeId: z.string().nullish()
})

const EmailMarketingConfigSchema = z.object({
  klaviyo: KlaviyoConfigSchema.nullish(),
  convertKit: ConvertKitConfigSchema.nullish(),
  mailChimp: MailChimpConfigSchema.nullish()
})

const Listing = z.object({
  productId: z.string(),
  name: z.string(),
  slug: z.string(),
  published: z.boolean(),
  customization: ListingCustomization,
  analytics: AnalyticsConfigSchema.nullish(),
  emailMarketing: EmailMarketingConfigSchema.nullish()
})

const Product = z.object({
  name: z.string(),
  id: z.string(),
  pricing: Pricing,
  defaultListing: Listing.optional(),
  fulfillment: Fulfillment
})

const BulkTierInfo = z.object({
  low: z.number(),
  label: z.string()
})

const PricingInfo = z.object({
  initialPrice: z.number(),
  finalPrice: z.number(),
  discount: z.number(),
  quantity: z.number(),
  initialPriceLabel: z.string().nullish(),
  discountLabel: z.string().nullish(),
  finalPriceLabel: z.string(),
  promoCode: z.string().nullish(),
  bulkTiers: z.array(BulkTierInfo).nullish(),
  currency: z.string().nullish()
})

const Customer = z.object({
  first: z.string().min(1),
  last: z.string().min(1),
  email: z.string().email(),
  company: z.string().nullish(),
  newsletter: z.boolean()
})

const Payment = z.object({
  transactionId: z.string().optional(),
  promoCodeId: z.string().optional(),
  promoCode: z.string().optional(),
  quantity: z.number(),
  initialAmount: z.number(),
  finalAmount: z.number(),
  currency: z.string().optional()
})

const Basic = z.object({
  __typename: z.literal("Basic")
})

const Gift = z.object({
  __typename: z.literal("Gift"),
  recipient: z.string().email(),
  sendDate: z.date().nullable(),
  giftMessage: z.string().optional()
})

const Bulk = z.object({
  __typename: z.literal("Bulk"),
  noSeats: z.number(),
  allocated: z.number().optional()
})

const OrderAttributes = z.discriminatedUnion("__typename", [Basic, Gift, Bulk])

const FulfillmentOrderAttributes = z.object({
  channelType: z.enum(["sms", "whatsapp", "msteams"]),
  startDate: z.date().nullish(),
  timezone: z.string(),
  first: z.string(),
  last: z.string(),
  phone: z.string()
})

export const FulfillmentOrder = z.object({
  __typename: z.literal("FulfillmentOrder"),
  id: z.string(),
  parentOrderId: z.string(),
  productId: z.string(),
  email: z.string(),
  onboardAttributes: FulfillmentOrderAttributes.nullish(),
  taskToken: z.string().optional(),
  executionId: z.string().optional(),
  status: z.string().nullish()
})

const SessionInfoSchema = z.object({
  userAgent: z.string(),
  ipAddress: z.string(),
  url: z.string(),
  adParams: z.record(z.string())
})

export const Order = z.object({
  id: z.string(),
  __typename: z.literal("Order"),
  productId: z.string(),
  slug: z.string(),
  terms: z.boolean(),
  customer: Customer,
  session: SessionInfoSchema,
  payment: Payment,
  attributes: OrderAttributes,
  taskToken: z.string().optional(),
  createdAt: z.date(),
  status: z.enum(["CREATED", "COMPLETED"])
})

export const IOrder = z.discriminatedUnion("__typename", [
  Order,
  FulfillmentOrder
])

const PaymentIntent = z.object({
  id: z.string(),
  amount: z.number(),
  client_secret: z.string()
})

const PaginatedRequestArgs = {
  limit: z.number().optional(),
  startToken: z.string().optional()
}

const PaginatedOrders = z.object({
  nextToken: z.string().optional(),
  items: Order.array()
})

const PaginatedFulfillmentOrders = z.object({
  nextToken: z.string().optional(),
  items: FulfillmentOrder.array()
})

export const ECommerceGqlZodSchemaV3 = defineSchema({
  Query: {
    listProducts: z.function().returns(z.array(Product)),
    listOrders: z
      .function()
      .args(z.object({ productId: z.string() }).extend(PaginatedRequestArgs))
      .returns(PaginatedOrders),
    findProduct: z
      .function()
      .args(z.object({ id: z.string() }))
      .returns(Product),
    findListing: z
      .function()
      .args(z.object({ slug: z.string() }))
      .returns(Listing),
    validatePromoCode: z
      .function()
      .args(z.object({ productId: z.string(), code: z.string() }))
      .returns(z.string()),
    getListingPriceInfo: z
      .function()
      .args(
        z.object({
          productId: z.string(),
          quantity: z.number(),
          promoCodeId: z.string().optional()
        })
      )
      .returns(PricingInfo),
    getProductListings: z
      .function()
      .args(z.object({ productId: z.string(), name: z.string().optional() }))
      .returns(z.array(Listing)),
    getAllListings: z.function().returns(z.array(Listing)),
    findOrder: z
      .function()
      .args(z.object({ id: z.string() }))
      .returns(IOrder),
    listFulfillmentOrders: z
      .function()
      .args(
        z.object({
          orderId: z.string(),
          startToken: z.string().optional(),
          limit: z.number().optional()
        })
      )
      .returns(PaginatedFulfillmentOrders),
    listPromoCodes: z
      .function()
      .args(
        z.object({
          productId: z.string(),
          startToken: z.string().optional(),
          limit: z.number().optional()
        })
      )
      .returns(PaginatedPromoCode)
  },
  Mutation: {
    createProduct: z
      .function()
      .args(Product.pick({ name: true, fulfillment: true, pricing: true }))
      .returns(Product),
    updateProduct: z
      .function()
      .args(Product.partial().extend({ id: z.string() }))
      .returns(Product),
    deleteProduct: z
      .function()
      .args(z.object({ id: z.string() }))
      .returns(z.boolean().nullish()),
    createPromoCode: z
      .function()
      .args(PromoCode.omit({ id: true, uses: true }))
      .returns(PromoCode),
    updatePromoCode: z
      .function()
      .args(
        PromoCode.partial()
          .omit({ uses: true })
          .extend({ id: z.string(), productId: z.string() })
      )
      .returns(PromoCode),
    deletePromoCode: z
      .function()
      .args(z.object({ id: z.string(), productId: z.string() }))
      .returns(z.boolean().optional()),
    createListing: z
      .function()
      .args(
        Listing.pick({
          productId: true,
          slug: true,
          name: true,
          customization: true,
          analytics: true
        })
      )
      .returns(Listing),
    updateListing: z
      .function()
      .args(
        Listing.partial().extend({
          slug: z.string()
        })
      )
      .returns(Listing),
    deleteListing: z
      .function()
      .args(z.object({ slug: z.string() }))
      .returns(z.boolean().nullish()),
    createOrder: z
      .function()
      .args(
        Order.omit({
          id: true,
          fulfillmentOrders: true,
          __typename: true,
          createdAt: true,
          status: true
        }).extend({
          session: SessionInfoSchema.omit({ userAgent: true, ipAddress: true })
        })
      )
      .returns(Order),
    inviteBulkParticipant: z
      .function()
      .args(z.object({ id: z.string(), email: z.string() }))
      .returns(z.boolean().nullish()),
    startCourse: z
      .function()
      .args(
        z.object({
          fulfillmentOrderId: z.string(),
          onboardAttributes: FulfillmentOrderAttributes
        })
      )
      .returns(z.boolean().nullish()),
    createPaymentIntent: z
      .function()
      .args(
        z.object({
          productId: z.string(),
          quantity: z.number(),
          promoCodeId: z.string().optional()
        })
      )
      .returns(PaymentIntent),
    updatePaymentIntent: z
      .function()
      .args(
        z.object({
          id: z.string(),
          productId: z.string(),
          quantity: z.number(),
          promoCodeId: z.string().optional(),
          orderId: z.string().optional(),
          customerEmail: z.string().optional(),
          productTitle: z.string().optional(),
          slug: z.string().optional()
        })
      )
      .returns(PaymentIntent),
    setupMailchimpInstance: z
      .function()
      .args(
        z.object({
          code: z.string(),
          slug: z.string(),
          listId: z.string(),
          accountId: z.string()
        })
      )
      .returns(z.boolean())
  },
  types: {
    BulkTier,
    Discount,
    DiscountType,
    Pricing,
    Product,
    Listing,
    AnalyticsConfig: AnalyticsConfigSchema,
    PixelAnalyticsConfig: PixelAnalyticsConfigSchema,
    GoogleAdsAnalyticsConfig: GoogleAdsAnalyticsConfigSchema,
    EmailMarketingConfig: EmailMarketingConfigSchema,
    KlaviyoConfig: KlaviyoConfigSchema,
    ConvertKitConfig: ConvertKitConfigSchema,
    MailChimpConfig: MailChimpConfigSchema,
    PricingInfo,
    BulkTierInfo,
    ListingCustomization,
    FontFamily,
    NavLink,
    Fulfillment,
    ProgramFulfillment,
    FulfillmentChannels,
    ChannelRef: ChannelRefSchema,
    VoidFulfillment,
    HttpFulfillment,
    PromoCode,
    PaginatedPromoCode,

    // Order
    Order,
    SessionInfo: SessionInfoSchema,
    PaginatedOrders,
    IOrder,
    FulfillmentOrder,
    PaginatedFulfillmentOrders,
    FulfillmentOrderAttributes,
    Customer,
    Payment,
    OrderAttributes,
    Basic,
    Gift,
    Bulk,
    //Payment
    PaymentIntent
  }
})
