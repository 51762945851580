import { CustomTypographyType } from "../../theme/typography"
import { packSx, Text, TextProps, useMantineTheme } from "@mantine/core"
import React from "react"

const AppText = React.forwardRef<
  HTMLDivElement,
  {
    type: CustomTypographyType
  } & TextProps
>((props, ref) => {
  const th = useMantineTheme()
  return (
    <Text
      ref={ref}
      {...props}
      sx={[th.other.text[props.type], ...packSx(props.sx)]}
    >
      {props.children}
    </Text>
  )
})

export default AppText
