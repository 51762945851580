import { z } from "zod"
import { defineSchema } from "@authoritive/gql-zod/dist/GQLZodSchema"

const AIMessage = z.object({
  message: z.string(),
  reply: z.string().nullish()
})

const AITopic = z.object({
  name: z.string(),
  messages: AIMessage.array()
})

const AICmdGenerateOutlineOutput = z.object({
  title: z.string(),
  summary: z.string(),
  topics: AITopic.array()
})

const AICmdGenerateOutlineInput = z.object({
  duration: z.number(),
  sourceType: z.enum(["youtube", "website", "objective"]),
  source: z.string()
})

export const AICmdGenerateOutline = z.object({
  __typename: z.literal("AICmdGenerateOutline"),
  input: AICmdGenerateOutlineInput,
  output: AICmdGenerateOutlineOutput.nullish()
})

const AICmdGenerateMessageInput = z.object({
  prompt: z.string().nullish(),
  previousMessages: z.string().array(),
  programName: z.string(),
  programSummary: z.string(),
  topicName: z.string()
})

const AICmdGenerateSummaryInput = z.object({
  prompt: z.string().nullish(),
  programName: z.string(),
  programObjective: z.string(),
  programDurationDays: z.number()
})

export const AICmdGenerateMessage = z.object({
  __typename: z.literal("AICmdGenerateMessage"),
  input: AICmdGenerateMessageInput,
  output: z.string().nullish()
})

export const AICmdGenerateSummary = z.object({
  __typename: z.literal("AICmdGenerateSummary"),
  input: AICmdGenerateSummaryInput,
  output: z.string().nullish()
})

const CreateAICommand = z.object({
  __typename: z.enum([
    "AICmdGenerateOutline",
    "AICmdGenerateMessage",
    "AICmdGenerateSummary"
  ]),
  input: z.record(z.any())
})

const AICommand = z.discriminatedUnion("__typename", [
  AICmdGenerateOutline,
  AICmdGenerateMessage,
  AICmdGenerateSummary
])

const TicketStatus = z.enum(["QUEUED", "IN_PROGRESS", "COMPLETED", "FAILED"])

export const AIRequestTicket = z.object({
  id: z.string(),
  createdAt: z.date(),
  completedAt: z.date().nullish(),
  status: TicketStatus,
  command: AICommand,
  TTL: z.number(),
  errorMessage: z.string().nullish()
})

export const AIServiceGqlZodSchema = defineSchema({
  types: {
    AIRequestTicket,
    AICmdGenerateOutline,
    AICmdGenerateOutlineInput,
    AICmdGenerateOutlineOutput,
    AICmdGenerateMessage,
    AICmdGenerateMessageInput,
    AICmdGenerateSummaryInput,
    AICmdGenerateSummary,
    AICommand,
    AITopic,
    AIMessage,
    TicketStatus
  },
  Mutation: {
    createAITicket: z.function().args(CreateAICommand).returns(z.string())
  },
  Query: {
    getAITicket: z
      .function()
      .args(z.object({ id: z.string() }))
      .returns(AIRequestTicket)
  }
})
