import { ReactNode, useEffect, useState } from "react"
import { useGetAccount } from "../../services/platformApiHooks"
import { LoadingOverlay } from "@mantine/core"
import { useNavigate, useSearchParams } from "react-router-dom"
import { z } from "zod"
import { platformClient } from "../../services/PlatformClient"

const ImportAIProgramActionSchema = z.object({
  type: z.literal("IMPORT_AI_PROGRAM"),
  aiContentId: z.string()
})
const ActionSchema = z.union([
  ImportAIProgramActionSchema,
  ImportAIProgramActionSchema
])

export default function ActionHandler(props: { children: ReactNode }) {
  const [params, setParams] = useSearchParams()
  const [running, setRunning] = useState(false)
  const navigate = useNavigate()

  async function performAction(action: string) {
    console.log("running action:", action)
    const actionJson = JSON.parse(atob(action))
    const actionPayload = ActionSchema.parse(actionJson)
    console.log("payload:", actionPayload)
    if (actionPayload.type === "IMPORT_AI_PROGRAM") {
      const programId = await platformClient.importProgram(
        { program: { aiContentId: actionPayload.aiContentId } },
        true
      )
      navigate(`/programs/${programId}/publish?tab=publishing`)
    }
  }

  useEffect(() => {
    const action = params.get("action")
    if (!action) {
      return
    }
    setRunning(true)
    params.delete("action")
    setParams(params, { replace: true })
    performAction(action).finally(() => setRunning(false))
  }, [params.get("action")])

  return (
    <>
      {props.children}
      <LoadingOverlay visible={running} />
    </>
  )
}
