/**
   Events documented here:  
   [Event Catalog](https://docs.google.com/spreadsheets/d/1I_u27zmQ32dVUyDiOw3auXHNSdqIuWp1XHfU_fFz6U0/edit#gid=0)
 */

import { z } from "zod"

const CreatorSignedUpNewEvent = z.object({
  type: z.literal("CREATOR_SIGNED_UP_NEW"),
  payload: z.object({
    creatorEmail: z.string(),
    creatorName: z.string(),
    creatorFirst: z.string(),
    creatorLast: z.string(),
    accountId: z.string(),
    referrer: z.string().optional(),
    survey: z.record(z.any()).optional()
  })
})

const CreatorLoadedAppEvent = z.object({
  type: z.literal("CREATOR_LOADED_APP"),
  payload: z.object({
    creatorEmail: z.string(),
    accountId: z.string()
  })
})

const CreatorSignedUpInvitedEvent = z.object({
  type: z.literal("CREATOR_SIGNED_UP_INVITED"),
  payload: z.object({
    creatorEmail: z.string(),
    creatorName: z.string(),
    creatorFirst: z.string(),
    creatorLast: z.string(),
    accountId: z.string()
  })
})

const CreatorLoggedInEvent = z.object({
  type: z.literal("CREATOR_LOGGED_IN"),
  payload: z.object({
    creatorEmail: z.string(),
    creatorName: z.string(),
    accountId: z.string()
  })
})

const ProgramCreatedEvent = z.object({
  type: z.literal("PROGRAM_CREATED"),
  payload: z.object({
    creatorEmail: z.string(),
    accountId: z.string(),
    programId: z.string()
  })
})

const AIProgramCreatedEvent = z.object({
  type: z.literal("AI_PROGRAM_CREATED"),
  payload: z.object({
    creatorEmail: z.string(),
    accountId: z.string(),
    programId: z.string(),
    title: z.string(),
    duration: z.number()
  })
})

const ProgramInfoUpdatedEvent = z.object({
  type: z.literal("PROGRAM_INFO_UPDATED"),
  payload: z.object({
    creatorEmail: z.string(),
    accountId: z.string(),
    programId: z.string(),
    programName: z.string()
  })
})

const ProgramCadenceUpdatedEvent = z.object({
  type: z.literal("PROGRAM_CADENCE_UPDATED"),
  payload: z.object({
    creatorEmail: z.string(),
    accountId: z.string(),
    programId: z.string()
  })
})

const TopicCreatedEvent = z.object({
  type: z.literal("TOPIC_CREATED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    programId: z.string()
  })
})

const TopicContentUpdatedEvent = z.object({
  type: z.literal("TOPIC_CONTENT_UPDATED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    programId: z.string()
  })
})

const PublishPageCreatedEvent = z.object({
  type: z.literal("PUBLISH_PAGE_CREATED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    programId: z.string(),
    slug: z.string()
  })
})

const PublishPageUpdatedEvent = z.object({
  type: z.literal("PUBLISH_PAGE_UPDATED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    programId: z.string(),
    slug: z.string()
  })
})

const PricingUpdatedEvent = z.object({
  type: z.literal("PRICING_UPDATED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    programId: z.string()
  })
})

const ProgramPublishedEvent = z.object({
  type: z.literal("PROGRAM_PUBLISHED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    programId: z.string(),
    programName: z.string(),
    slug: z.string()
  })
})

const ProgramUnpublishedEvent = z.object({
  type: z.literal("PROGRAM_UNPUBLISHED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    programId: z.string(),
    programName: z.string(),
    slug: z.string()
  })
})

const ProgramArchivedEvent = z.object({
  type: z.literal("PROGRAM_ARCHIVED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    programId: z.string(),
    programName: z.string()
  })
})

const ProgramUnarchivedEvent = z.object({
  type: z.literal("PROGRAM_UNARCHIVED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    programId: z.string(),
    programName: z.string()
  })
})

const StripeConnectInitiatedEvent = z.object({
  type: z.literal("STRIPE_CONNECT_INITIATED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string()
  })
})

const ProgramSubmittedForReviewEvent = z.object({
  type: z.literal("PROGRAM_SUBMITTED_FOR_REVIEW"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    programId: z.string(),
    programName: z.string(),
    submissionId: z.string()
  })
})

const ProgramAcceptedEvent = z.object({
  type: z.literal("PROGRAM_ACCEPTED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    programId: z.string()
  })
})

const ProgramRejectedEvent = z.object({
  type: z.literal("PROGRAM_REJECTED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    programId: z.string(),
    reason: z.string()
  })
})

const WizardExitedEvent = z.object({
  type: z.literal("WIZARD_EXITED"),
  payload: z.object({
    accountId: z.string(),
    creatorEmail: z.string(),
    stepNumber: z.number(),
    completed: z.boolean()
  })
})

export const PlatformEventSchema = z.discriminatedUnion("type", [
  CreatorLoadedAppEvent,
  CreatorSignedUpNewEvent,
  CreatorSignedUpInvitedEvent,
  CreatorLoggedInEvent,
  AIProgramCreatedEvent,
  ProgramCreatedEvent,
  ProgramInfoUpdatedEvent,
  ProgramCadenceUpdatedEvent,
  TopicCreatedEvent,
  TopicContentUpdatedEvent,
  PublishPageCreatedEvent,
  PublishPageUpdatedEvent,
  PricingUpdatedEvent,
  ProgramPublishedEvent,
  ProgramUnpublishedEvent,
  StripeConnectInitiatedEvent,
  ProgramSubmittedForReviewEvent,
  ProgramAcceptedEvent,
  ProgramRejectedEvent,
  WizardExitedEvent,
  ProgramArchivedEvent,
  ProgramUnarchivedEvent
])

/*


COMPLETE_WIZARD	Creator completes wizard flow	CREATOR_EMAIL
		ACCOUNT_ID
		?Steps Completed

REVIEW_ACCEPTED	Admin accepts a submission	CREATOR_EMAIL
		ACCOUNT_ID
		PROGRAM_ID
		PROGRAM_NAME

REVIEW_REJECTED	Admin rejects a submission	CREATOR_EMAIL
		ACCOUNT_ID
		PROGRAM_ID
		PROGRAM_NAME
		REJECT_REASON


 */
