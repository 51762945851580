import { z } from "zod"
import { defineSchema } from "@authoritive/gql-zod/dist/GQLZodSchema"

const Account = z.object({
  id: z.string(),
  name: z.string(),
  stripeAccountId: z.string().optional(),
  stripeCustomerId: z.string().optional(),
  milestones: z.record(z.string(), z.date()).optional(),
  referrer: z.string().optional(),
  survey: z.record(z.any()).optional(),
  subscriptionCharges: z.number().optional(),
  subscriptionPayments: z.number().optional()
})

const PaginatedAccounts = z.object({
  nextToken: z.string().optional(),
  items: Account.array()
})

const AccountSearch = z.object({
  total: z.number(),
  data: Account.array()
})

const User = z.object({
  email: z.string(),
  name: z.string(),
  activeAccountId: z.string()
})

const UserRole = z.enum(["ADMIN", "EDITOR", "VIEWER", "INACTIVE", "REVIEWER"])

const AccountUser = z.object({
  accountId: z.string(),
  email: z.string(),
  role: UserRole
})

const AccountUserEnriched = AccountUser.extend({
  accountName: z.string()
})

const PaginatedUserAccounts = z.object({
  nextToken: z.string().optional(),
  items: AccountUserEnriched.array()
})

const AccountUserEnrichedSearch = z.object({
  total: z.number(),
  data: AccountUserEnriched.array()
})

const StripeLink = z.object({
  url: z.string()
})

const StripeAccount = z.object({
  id: z.string(),
  email: z.string(),
  details_submitted: z.boolean()
})

const StripeInvoiceSettings = z.object({
  default_payment_method: z.string().nullish()
})

const StripeCustomer = z.object({
  id: z.string(),
  invoice_settings: StripeInvoiceSettings
})

const StripePaymentData = z.object({
  id: z.string(),
  amount: z.number(),
  amount_reversed: z.number(),
  created: z.number(),
  currency: z.string(),
  description: z.string().nullish(),
  destination_payment: z.string()
})

const StripePayment = z.object({
  object: z.string(),
  data: z.array(StripePaymentData),
  has_more: z.boolean(),
  url: z.string()
})

// res = MAX( (rspp * tfpp * price) + fcpp, mcpp )

const ProgramPlanTemplate = z.object({
  fcpp: z.number(), // fixedCostPerParticipant: z.number(), // fcpp
  rspp: z.number(), //revSharePerParticipant: z.number(), // rspp
  tfpp: z.number(), // transactionFeePerParticipant: z.number(), // tfpp
  mcpp: z.number(), // minCostPerParticipant: z.number(), // mcpp
  prmin: z.number().nullish(), // participantRangeMin: z.number().optional(), // prmin
  prmax: z.number().nullish() // participantRangeMax: z.number().optional() // prmax
})

export const ProgramPlan = ProgramPlanTemplate.extend({
  id: z.string(),
  programId: z.string(),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  createdAt: z.date()
})

const AccountPricing = z.object({
  monthly: z.number(),
  yearly: z.number()
})

const AccountFeatures = z.object({
  maxPrograms: z.number().nullish(),
  maxPublished: z.number().nullish(),
  maxUsers: z.number().nullish(),
  maxTestNumbers: z.number().nullish()
})

export const AccountPlanTemplate = z.object({
  id: z.string(),
  name: z.string(),
  shortDescription: z.string(),
  featureDescription: z.string(),
  pricing: AccountPricing,
  features: AccountFeatures,
  level: z.number(),
  defaultProgramPlans: ProgramPlanTemplate.array(),
  hidden: z.boolean().nullish(),
  fallback: z.boolean().nullish()
})

export const AccountPlan = AccountPlanTemplate.extend({
  id: z.string(),
  accountId: z.string(),
  templateId: z.string().optional(),
  startDate: z.date(),
  endDate: z.date().nullish(),
  createdAt: z.date(),
  billing: z.enum(["monthly", "yearly"])
})

export const PlanCharge = z.object({
  id: z.string(),
  accountId: z.string(),
  planId: z.string(),
  startDate: z.date(),
  endDate: z.date(),
  amount: z.number(),
  transactionId: z.string().optional(),
  status: z.enum(["COLLECTED", "NOT_COLLECTED", "COLLECTION_FAILED"]),
  statusReason: z.string().optional(),
  createdAt: z.date(),
  updatedAt: z.date()
})

export const AccountsGqlZodSchema = defineSchema({
  Query: {
    whoami: z.function().args().returns(User),

    getAccountUser: z.function().args().returns(AccountUserEnriched),

    getAccount: z
      .function()
      .args(z.object({ accountId: z.string() }))
      .returns(Account),

    getActiveAccountPlan: z
      .function()
      .args(z.object({ accountId: z.string() }))
      .returns(z.array(AccountPlan).optional()),

    listAccountPlanTemplates: z
      .function()
      .args(z.object({ hidden: z.boolean().nullish() }))
      .returns(AccountPlanTemplate.array()),

    // returns AccountsUsers by accountId
    listAccountUsers: z
      .function()
      .args(z.object({ accountId: z.string() }))
      .returns(z.array(AccountUser)),
    // returns AccountsUsers by email
    listUserAccounts: z
      .function()
      .args(
        z.object({
          startToken: z.string().optional(),
          limit: z.number().optional()
        })
      )
      .returns(PaginatedUserAccounts),
    searchUserAccounts: z
      .function()
      .args(
        z.object({
          searchValue: z.string().optional(),
          offset: z.number().optional(),
          limit: z.number().optional()
        })
      )
      .returns(AccountUserEnrichedSearch),
    getStripeAccount: z.function().returns(StripeAccount),
    getStripeCustomer: z.function().returns(StripeCustomer.optional()),
    listStripePayments: z
      .function()
      .args(
        z.object({
          limit: z.number(),
          starting_after: z.string().nullish(),
          ending_before: z.string().nullish()
        })
      )
      .returns(StripePayment),

    // admin functions
    listAccounts: z
      .function()
      .args(
        z.object({
          startToken: z.string().optional(),
          limit: z.number().optional()
        })
      )
      .returns(PaginatedAccounts),

    searchAccounts: z
      .function()
      .args(
        z.object({
          searchValue: z.string().optional(),
          offset: z.number().optional(),
          limit: z.number().optional()
        })
      )
      .returns(AccountSearch),

    listAccountPlans: z
      .function()
      .args(z.object({ accountId: z.string() }))
      .returns(AccountPlan.array()),

    findAccountPlanTemplate: z
      .function()
      .args(z.object({ id: z.string() }))
      .returns(AccountPlanTemplate),

    listAccountCharges: z
      .function()
      .args(z.object({ accountId: z.string() }))
      .returns(PlanCharge.array())
  },
  Mutation: {
    // create stripe account in Stripe Connect
    createStripeAccount: z
      .function()
      .args(z.object({ email: z.string(), country: z.string() }))
      .returns(StripeLink),

    createConnectStripeAccountLink: z.function().args().returns(StripeLink),

    createStripeCustomerPortalLink: z
      .function()
      .args(z.object({ return_url: z.string() }))
      .returns(StripeLink),

    addUserToAccount: z
      .function()
      .args(z.object({ email: z.string(), role: UserRole }))
      .returns(AccountUser),

    // update role for user
    updateUserAccount: z
      .function()
      .args(z.object({ email: z.string(), role: UserRole }))
      .returns(AccountUser),

    // update account name, ...
    updateAccount: z
      .function()
      .args(Account.pick({ name: true }))
      .returns(Account),

    // update stripe account id
    updateStripeAccountId: z
      .function()
      .args(Account.pick({ stripeAccountId: true }))
      .returns(Account),

    // change active accountId -> user needs to signout out and in again
    switchUserActiveAccount: z
      .function()
      .args(z.object({ newAccountId: z.string() }))
      .returns(User),

    saveAccountPlanTemplate: z
      .function()
      .args(AccountPlanTemplate.extend({ id: z.string().optional() }))
      .returns(AccountPlanTemplate),

    deleteAccountPlanTemplate: z
      .function()
      .args(z.object({ id: z.string() }))
      .returns(z.boolean().optional()),

    updateAccountPlanTemplate: z
      .function()
      .args(AccountPlanTemplate)
      .returns(AccountPlanTemplate),

    selectAccountPlan: z
      .function()
      .args(
        z.object({ id: z.string(), billing: z.enum(["monthly", "yearly"]) })
      )
      .returns(z.boolean().nullish()),

    saveAccountPlan: z
      .function()
      .args(
        AccountPlan.extend({ id: z.string().optional() }).omit({
          createdAt: true
        })
      )
      .returns(AccountPlan),
    deleteAccountPlan: z
      .function()
      .args(z.object({ id: z.string(), accountId: z.string() }))
      .returns(z.boolean().optional()),

    collectCharge: z
      .function()
      .args(z.object({ chargeId: z.string(), accountId: z.string() }))
      .returns(PlanCharge),

    markChargeAsCollected: z
      .function()
      .args(
        z.object({
          chargeId: z.string(),
          accountId: z.string(),
          transactionId: z.string()
        })
      )
      .returns(PlanCharge)
  },
  types: {
    User,
    UserRole,
    Account,
    AccountSearch,
    AccountUser,
    AccountUserEnriched,
    PaginatedUserAccounts,
    PaginatedAccounts,
    AccountUserEnrichedSearch,
    StripeAccount,
    StripeCustomer,
    StripeInvoiceSettings,
    StripeLink,
    StripePayment,
    StripePaymentData,
    AccountPlanTemplate,
    AccountPlan,
    PlanCharge,
    AccountFeatures,
    AccountPricing,
    ProgramPlanTemplate,
    ProgramPlan
  }
})
