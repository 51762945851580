import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { Auth } from "aws-amplify"
import objFilterUndefined from "../../../../shared/utils/objFilterUndefined"
import { useAuthContext } from "../AuthContext"
import { createFormContext, zodResolver } from "@mantine/form"
import { useLocation } from "react-router-dom"
import {
  InvitationSchema,
  SignUpSchemaPasswordMatch,
  SurveyFormSchema
} from "../../../../shared/formSchemas"
import SignUpDetails from "./components/SignUpDetails"
import CompleteSurveyComponent from "./components/CompleteSurvey"
import { SignUpSchemaType } from "../../../../shared/types"

export const [SignUpFormProvider, useSignUpFormContext, useSignUpForm] =
  createFormContext<SignUpSchemaType>()

export default function SignUp() {
  const [signUpStep, setSignUpStep] = useState<"INFO" | "SURVEY">("INFO")
  const { goToState } = useAuthContext()

  const queryParams = new URLSearchParams(useLocation().search)

  const invitationParams = (() => {
    try {
      const invitationId = queryParams.get("invitationId")
      if (!invitationId) return undefined
      return InvitationSchema.parse(JSON.parse(atob(invitationId)))
    } catch (e) {
      return undefined
    }
  })()
  const referrer = queryParams.get("referrer") || undefined

  const signUpForm = useSignUpForm({
    initialValues: {
      userFullName: "",
      username: invitationParams?.email ?? "",
      password: "",
      confirmPassword: "",
      accountId: invitationParams?.accountId,
      referrer: referrer,
      terms: false,
      //@ts-ignore
      onlineContent: null,
      audienceSize: "",
      genre: ""
    },
    validate:
      signUpStep === "INFO"
        ? zodResolver(SignUpSchemaPasswordMatch)
        : zodResolver(SurveyFormSchema)
  })

  const {
    mutate: signUp,
    isLoading,
    error,
    isError
  } = useMutation(
    (args: {
      username: string
      password: string
      name: string
      accountId?: string
      referrer?: string
      survey?: {
        onlineContent: string | false
        audienceSize: string
        genre: string
      }
    }) =>
      Auth.signUp({
        username: args.username,
        password: args.password,
        clientMetadata: objFilterUndefined({
          accountId: args.accountId,
          referrer: args.referrer,
          survey: JSON.stringify(args.survey)
        }),
        attributes: {
          name: args.name
        },
        autoSignIn: { enabled: true }
      }),
    {
      onSuccess: () =>
        goToState("VerifyAccount", {
          username: signUpForm.values.username
        }),
      onError: () => setSignUpStep("INFO")
    }
  )

  const handleSignUp = () => {
    const valRes = signUpForm.validate()

    console.log("signup", valRes)
    if (valRes.hasErrors) return
    else {
      signUp({
        name: signUpForm.values.userFullName,
        password: signUpForm.values.password,
        username: signUpForm.values.username,
        accountId: signUpForm.values.accountId,
        referrer: signUpForm.values.referrer,
        survey: {
          onlineContent: signUpForm.values.onlineContent,
          genre: signUpForm.values.genre,
          audienceSize: signUpForm.values.audienceSize
        }
      })
    }
  }

  const handleOnSubmitSignUpDetails = () => {
    if (invitationParams?.accountId) handleSignUp()
    else {
      const valRes = signUpForm.validate()
      if (!valRes.hasErrors) setSignUpStep("SURVEY")
    }
  }

  return (
    <SignUpFormProvider form={signUpForm}>
      <form
        style={{ height: "100%" }}
        id="sign-up-form"
        onSubmit={(e) => {
          e.preventDefault()
          handleSignUp()
        }}
      >
        <SignUpDetails
          isLoading={isLoading}
          visible={signUpStep === "INFO"}
          handleOnClick={handleOnSubmitSignUpDetails}
          invitationName={invitationParams?.name}
          referrer={referrer}
          hasError={isError}
          error={error}
        />
        <CompleteSurveyComponent
          visible={signUpStep === "SURVEY"}
          isLoading={isLoading}
          onClickBack={() => setSignUpStep("INFO")}
        />
      </form>
    </SignUpFormProvider>
  )
}
