import React, { Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import GeneratePageLoader from "./generate/components/GeneratePageLoader"
import OauthModule from "./oauth"

const LazyDashboardModule = React.lazy(() => import("./dashboard"))
const LazyEditorModule = React.lazy(() => import("./editor"))

const LazyAiGenerateModule = React.lazy(() => {
  return Promise.all([
    import("./generate"),
    new Promise((resolve) => setTimeout(resolve, 3500))
  ]).then(([module]) => module)
})

export function AppRouter() {
  return (
    <div>
      <Routes>
        <Route
          path={"programs/:programId/*"}
          element={
            <Suspense>
              <LazyEditorModule />
            </Suspense>
          }
        />
        <Route path={"oauth/*"} element={<OauthModule />} />
        <Route
          path={"*"}
          element={
            <Suspense>
              <LazyDashboardModule />
            </Suspense>
          }
        />
        <Route
          path={"generate/"}
          element={
            <Suspense fallback={<GeneratePageLoader />}>
              <LazyAiGenerateModule />
            </Suspense>
          }
        >
          <Route
            path={":ticketId/*"}
            element={
              <Suspense fallback={<GeneratePageLoader />}>
                <LazyAiGenerateModule />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </div>
  )
}
