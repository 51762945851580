import AppText from "../AppText"
import { Group, HoverCard, ThemeIcon } from "@mantine/core"
import { IconInfoCircle } from "@tabler/icons"
import { platformTypography } from "../../theme/typography"

export default function CardTitle(props: {
  title: string
  description?: string
  type?: keyof typeof platformTypography
}) {
  return (
    <Group spacing={8}>
      <AppText
        type={props.type || "title-large"}
        color={"text/half-dimmed"}
        py={2}
      >
        {props.title}
      </AppText>
      {props.description && (
        <HoverCard shadow="md" withArrow position="right">
          <HoverCard.Target>
            <ThemeIcon
              variant={"outline"}
              sx={{ border: "none" }}
              color={"text/dimmed"}
            >
              <IconInfoCircle size={20} />
            </ThemeIcon>
          </HoverCard.Target>
          <HoverCard.Dropdown sx={{ maxWidth: 550 }}>
            {props.description}
          </HoverCard.Dropdown>
        </HoverCard>
      )}
    </Group>
  )
}
