import { ReactNode } from "react"
import { createStyles, Stack } from "@mantine/core"

const usePageShellWrapperStyles = createStyles((th) => ({
  stack: {
    inset: 0,
    position: "relative",
    height: "100vh",
    width: "100vw",
    backgroundColor: th.colors["bg/inverted-l1-ai"][0],
    [th.fn.smallerThan("sm")]: {
      height: "calc(100vh - 77px)"
    }
  }
}))

export default function PageShellWrapper(props: { children: ReactNode }) {
  const { classes } = usePageShellWrapperStyles()
  return (
    <Stack spacing={0} className={classes.stack}>
      {props.children}
    </Stack>
  )
}
