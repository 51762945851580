import { Alert, Group, Stack, useMantineTheme } from "@mantine/core"
import { IconAlertCircle, IconInfoCircle } from "@tabler/icons"
import { platformColors } from "../../theme/colors"
import { ReactElement, ReactNode } from "react"
import { AlertType } from "../../types"
import AppText from "../AppText"

type AlertAttrs = {
  bgColor: keyof typeof platformColors
  textColor: keyof typeof platformColors
  icon: ReactElement
}

export default function AlertComponent(props: {
  type: AlertType
  message: ReactNode
  title?: string
}) {
  const th = useMantineTheme()
  const AlertMap: Record<AlertType, AlertAttrs> = {
    ["error"]: {
      bgColor: "bg/alert-error",
      textColor: "text/alert",
      icon: <IconAlertCircle color={th.colors["text/alert"][0]} />
    },
    ["warning"]: {
      bgColor: "bg/alert-warning",
      textColor: "text/warning",
      icon: <IconAlertCircle color={th.colors["text/warning"][0]} />
    },
    ["info"]: {
      bgColor: "bg/alert-info",
      textColor: "text/highlight",
      icon: <IconInfoCircle color={th.colors["text/highlight"][9]} />
    }
  }

  return (
    <Alert my={"sm"} color={AlertMap[props.type].bgColor}>
      <Group noWrap>
        {AlertMap[props.type].icon}
        <Stack spacing={0}>
          {props.title && (
            <AppText
              color={AlertMap[props.type].textColor}
              type={"title-medium"}
            >
              {props.title}
            </AppText>
          )}
          <AppText color={AlertMap[props.type].textColor} type={"label-medium"}>
            {props.message}
          </AppText>
        </Stack>
      </Group>
    </Alert>
  )
}
