import { Box, packSx, Sx } from "@mantine/core"
import { HTMLProps } from "react"

export function AuthoritiveLogoSingleLetter(props: {
  className?: HTMLProps<HTMLDivElement>["className"]
  dark?: boolean
  sx?: Sx
}) {
  const fill = props.dark ? "black" : "white"
  return (
    <Box
      tabIndex={0}
      component="svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      sx={packSx([
        {
          background: props.dark ? "white" : "transparent",
          borderRadius: 8,
          border: "1px solid #e2e4e7"
        },
        props.sx
      ])}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H32V32H0V0ZM9.2002 23.9427H12.046L18.3927 9.2622L16.9698 5.97095L9.2002 23.9427ZM22.6421 20.5306C22.6421 18.2848 21.7548 16.7043 19.6199 16.7043C18.2059 16.7043 17.4019 17.8411 17.4019 18.8947C17.4019 20.0315 17.9564 21.2237 20.1467 21.2237H20.8399C20.6458 23.3309 19.2595 24.5785 17.485 25.4935L18.0396 26.4362C20.6458 25.244 22.6421 22.7487 22.6421 20.5306Z"
        fill={fill}
      />
    </Box>
  )
}

function LogoInlineWithText(props: { dark?: boolean }) {
  const fill = props.dark ? "black" : "white"
  const invFill = props.dark ? "white" : "black"

  return (
    <svg
      width="157"
      height="32"
      viewBox="0 0 157 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46 21.2511H47.838L48.8102 18.7296H53.3061L54.2782 21.2511H56.1162L51.9848 10.6182H50.1314L46 21.2511ZM51.0581 12.9118L52.6529 17.0586H49.4633L51.0581 12.9118Z"
        fill={fill}
      />
      <path
        d="M64.6815 17.165C64.6815 18.684 63.907 19.7017 62.4031 19.7017C60.8842 19.7017 60.1247 18.684 60.1247 17.165C60.1247 13.6562 60.1247 10.6182 60.1247 10.6182H58.3018V17.3928C58.3018 19.7321 59.897 21.4334 62.4031 21.4334C64.9097 21.4334 66.5045 19.7321 66.5045 17.3928V10.6182H64.6815C64.6815 10.6182 64.6815 13.6562 64.6815 17.165Z"
        fill={fill}
      />
      <path
        d="M72.5878 21.2511H74.4107V12.3498H77.3422V10.6182H69.6562V12.3498H72.5878V21.2511Z"
        fill={fill}
      />
      <path
        d="M86.8546 14.9473H82.3128V10.6182H80.4902V21.2511H82.3128V16.6789H86.8546V21.2511H88.6775V10.6182H86.8546V14.9473Z"
        fill={fill}
      />
      <path
        d="M97.0793 10.436C93.9805 10.436 91.7021 12.7905 91.7021 15.9348C91.7021 19.0791 93.9805 21.4335 97.0793 21.4335C100.178 21.4335 102.456 19.0791 102.456 15.9348C102.456 12.7905 100.178 10.436 97.0793 10.436ZM97.0793 19.7019C94.9377 19.7019 93.5551 17.9854 93.5551 15.9348C93.5551 13.8842 94.9377 12.1677 97.0793 12.1677C99.2213 12.1677 100.603 13.8842 100.603 15.9348C100.603 17.9854 99.2213 19.7019 97.0793 19.7019Z"
        fill={fill}
      />
      <path
        d="M105.494 21.2511H107.317V17.2409H108.152L110.674 21.2511H112.801L110.142 17.1194C111.661 16.7397 112.512 15.5245 112.512 13.9295C112.512 11.9852 111.099 10.6182 108.988 10.6182H105.494V21.2511ZM108.76 15.5093H107.317V12.3498H108.76C109.93 12.3498 110.659 12.8815 110.659 13.9295C110.659 14.9776 109.93 15.5093 108.76 15.5093Z"
        fill={fill}
      />
      <path d="M116.371 21.2511H118.194V10.6182H116.371V21.2511Z" fill={fill} />
      <path
        d="M124.481 21.2511H126.304V12.3498H129.236V10.6182H121.55V12.3498H124.481V21.2511Z"
        fill={fill}
      />
      <path d="M132.596 21.2511H134.419V10.6182H132.596V21.2511Z" fill={fill} />
      <path
        d="M147.102 10.6182H145.203L142.408 17.4384L139.583 10.6182H137.593L142.347 21.6157L147.102 10.6182Z"
        fill={fill}
      />
      <path
        d="M151.885 12.3498H156.503V10.6182H150.062V21.2511H156.594V19.5194H151.885V16.6637H156.139V14.9321H151.885V12.3498Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H32V32H0V0ZM9.2002 23.9427H12.046L18.3927 9.2622L16.9698 5.97095L9.2002 23.9427ZM22.6421 20.5306C22.6421 18.2848 21.7548 16.7043 19.6199 16.7043C18.2059 16.7043 17.4019 17.8411 17.4019 18.8947C17.4019 20.0315 17.9564 21.2237 20.1467 21.2237H20.8399C20.6458 23.3309 19.2595 24.5785 17.485 25.4935L18.0396 26.4362C20.6458 25.244 22.6421 22.7487 22.6421 20.5306Z"
        fill={fill}
      />
    </svg>
  )
}
export default LogoInlineWithText
