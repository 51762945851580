import { z } from "zod"
import { fromObject } from "@authoritive/env-utils"

const envSchema = z.object({
  REACT_APP_COGNITO_REGION: z.enum([
    "us-east-1",
    "us-east-2",
    "us-west-1",
    "us-west-2"
  ]),
  REACT_APP_USER_POOLS_ID: z.string(),
  REACT_APP_USER_POOLS_WEB_CLIENT_ID: z.string(),
  REACT_APP_API_URL: z.string().url(),
  REACT_APP_MARKET_DOMAIN: z.string(),
  REACT_APP_GA4_MEASUREMENT_ID: z.string().optional(),
  REACT_APP_AIAPI_URL: z.string(),
  REACT_APP_AIAPI_KEY: z.string(),
  REACT_APP_MAILCHIMP_CLIENT_ID: z.string()
})

export const env = fromObject(process.env, envSchema)
