import { Button, Image, Paper, Stack } from "@mantine/core"
import AppText from "../AppText"
import mobileWarningImage from "../../images/mobile-warning-page.png"
import LogoInlineWithText from "../../logo"
import { useViewportSize } from "@mantine/hooks"

export function isMobileView() {
  const { width } = useViewportSize()
  const minViewportWidth = 768
  return width <= minViewportWidth
}

export default function ScreenSizeGuard(props: { children: React.ReactNode }) {
  const { width } = useViewportSize()
  const minUsageWidth = 1024 // md = 64em * 16p

  // if (width < minUsageWidth) return <MobileWarningPage />

  return <>{props.children}</>
}

function MobileWarningPage() {
  return (
    <Paper
      p="xl"
      sx={(th) => ({
        width: "100%",
        height: "100vh"
      })}
    >
      <Stack
        justify={"space-between"}
        align="center"
        sx={(th) => ({
          width: "100%",
          height: "90%"
        })}
      >
        <LogoInlineWithText dark />
        <AppText
          type={"body-large-bold"}
          color={"border/flow"}
          align={"center"}
        >
          To experience the full magic of our platform please visit us on your
          desktop or laptop computer.
        </AppText>
        <Image
          alt={"Mobile use is not supported"}
          fit={"contain"}
          src={mobileWarningImage}
          mx="auto"
        />
        <Stack>
          <AppText type={"body-large"} color={"text/dimmed"} align={"center"}>
            In the meantime you can always visit our help page to learn more on
            creating your program
          </AppText>
          <Button
            component={"a"}
            href="https://help.authoritive.com"
            target={"_blank"}
            color={"button/primary"}
          >
            <AppText type={"button-large"} color={"text/default"}>
              Learn More
            </AppText>
          </Button>
        </Stack>
      </Stack>
    </Paper>
  )
}
