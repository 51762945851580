import {string, z} from "zod"

export type GQLZodPrimitiveType =
  | z.ZodString
  | z.ZodNumber
  | z.ZodLiteral<any>
  | z.ZodEnum<any>
  | z.ZodBoolean
  | z.ZodDate
  | z.ZodRecord

export function isPrimitive(zt: z.ZodType): zt is GQLZodPrimitiveType {
  if (zt instanceof z.ZodString) return true
  if (zt instanceof z.ZodNumber) return true
  if (zt instanceof z.ZodLiteral) return true
  if (zt instanceof z.ZodEnum) return true
  if (zt instanceof z.ZodBoolean) return true
  if (zt instanceof z.ZodDate) return true
  if (zt instanceof z.ZodRecord) return true
  return false
}

export type GQLZodNamedType =
  | z.ZodObject<z.ZodRawShape>
  | z.ZodDiscriminatedUnion<"__typename", any>
  | z.ZodEnum<any>

export type GQLZodOutputType =
  | GQLZodPrimitiveType
  | z.ZodDiscriminatedUnion<"__typename", any>
  | z.ZodObject<z.ZodRawShape>
  | z.ZodOptional<GQLZodOutputType>
  | z.ZodNullable<GQLZodOutputType>
  | z.ZodArray<GQLZodOutputType>

// TODO Properly define GQLZodFunctionType with 0 or 1 arguments of type ZodObject
type GQLZodFunctionType = z.ZodFunction<any, any>

export type GQLZodSchema = {
  types: Record<string, GQLZodNamedType>
  Query: Record<string, GQLZodFunctionType>
  Mutation: Record<string, GQLZodFunctionType>
  annotations?: Record<string, string[]>
}

export type GQLZodNormalized = {[key:string]:
{[key:string]:z.ZodFunction<any, any> }
  }

export type GQLZodNormalize<GZS extends GQLZodSchema> = {
  Query: GZS["Query"],
  Mutation: GZS["Mutation"]
} & {
  // eslint-disable-next-line prettier/prettier
  [key in keyof GZS["types"]]: GZS["types"][key] extends z.ZodObject<infer Shape extends z.ZodRawShape> ? {
  [field in keyof Shape]:z.ZodFunction<z.ZodTuple<[]>, Shape[field]>
} : never
}


export function defineSchema<K extends GQLZodSchema>(v: K) {
  return v
}
