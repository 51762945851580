import { forwardRef } from "react"
import { ThemeIcon, ThemeIconProps } from "@mantine/core"

const TransparentIcon = forwardRef<HTMLDivElement, ThemeIconProps>(
  (props, ref) => {
    return (
      <ThemeIcon
        ref={ref}
        {...props}
        variant={"outline"}
        sx={{ border: "none" }}
      >
        {props.children}
      </ThemeIcon>
    )
  }
)

export default TransparentIcon
