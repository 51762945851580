import { Route, Routes } from "react-router-dom"
import MailchimpCallback from "./mailchimp/callback"

export default function OauthModule() {
  return (
    <Routes>
      <Route path={"mailchimp/callback"} element={<MailchimpCallback />} />
    </Routes>
  )
}
