import { useEffect, useState } from "react"
import PageShellWrapper from "../PageShellWrapper"
import AiGeneratePageHeader from "../PageHeader"
import WorkspaceContainer from "../WorkspaceContainer"
import GenerateAiIllustration from "./generate-loader-bg.png"
import { Center, Space, Stack, Title, Transition } from "@mantine/core"
import AppText from "../../../../shared/components/AppText"

export default function GeneratePageLoader() {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])
  return (
    <PageShellWrapper>
      <AiGeneratePageHeader />
      <WorkspaceContainer
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url("${GenerateAiIllustration}")`
        }}
      >
        <Center h={"100%"} w={"100%"} maw={600} mx={"auto"}>
          <Stack justify={"center"} align={"center"}>
            <Transition
              transition={"slide-down"}
              mounted={mounted}
              duration={700}
              timingFunction={"ease"}
            >
              {(styles) => (
                <Title
                  style={styles}
                  color={"text/white"}
                  align={"center"}
                  order={1}
                >
                  Wizardly Wonders!
                </Title>
              )}
            </Transition>
            <Space h={20} />
            <Transition
              transition={"slide-down"}
              mounted={mounted}
              duration={900}
              timingFunction={"ease"}
            >
              {(styles) => (
                <AppText
                  style={styles}
                  align={"center"}
                  color={"text/white"}
                  type={"headline-2"}
                >
                  Welcome to Authoritive AI, where we conjure up SMS experiences
                  from your content.
                </AppText>
              )}
            </Transition>
          </Stack>
        </Center>
      </WorkspaceContainer>
    </PageShellWrapper>
  )
}
